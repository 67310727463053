.video-section{
  padding-top: px(120);
  padding-bottom: px(120);
  box-shadow: 2px -2px 71px 13px rgba(0,0,0,0.18) inset;
  @media(max-width:1700px){
    padding-top: px(90);
    padding-bottom: px(90);
  }
  @media(max-width:1500px){
    padding-top: px(70);
    padding-bottom: px(70);
  }
  @media(max-width:1024px){
    padding-top: px(40);
    padding-bottom: px(40);
  }


  &__box {
    width: 100%;
    max-width: px(1440);
    margin: 0 auto;
    @media(max-width:1700px){
      max-width: px(1300);
    }
    @media(max-width:1500px){
      max-width: px(1200);
    }
  }

  &__video{
    width: 100%;
    position: relative;
    video{
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1.77777778 / 1;
    }
  }
}