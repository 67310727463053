.global-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: px(60);
  line-height: px(73);
  text-align: center;
  color: $baseBlack;
  margin-bottom: px(135);
  position: relative;
  z-index: 1;
  @media(max-width:1700px){
    font-size: px(54);
    line-height: px(66);
    margin-bottom: px(113);
  }
  @media(max-width:1500px){
    font-size: px(48);
    line-height: px(56);
    margin-bottom: px(80);
  }
  @media(max-width:1300px){
    font-size: px(44);
    line-height: px(52);
    margin-bottom: px(70);
  }
  @media(max-width:1024px){
    font-size: px(32);
    line-height: px(40);
    margin-bottom: px(40);
  }
  &::before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    content: attr(data-title);
    font-family: sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: px(120);
    line-height: px(146);
    text-align: center;
    text-transform: uppercase;
    color: $baseWhite;
    -webkit-text-stroke: 1px #DDDDDD;
    white-space: nowrap;
    @media(max-width:1700px){
      font-size: px(110);
      line-height: px(130);
    }
    @media(max-width:1500px){
      font-size: px(90);
      line-height: px(110);
    }
    @media(max-width:1300px){
      font-size: px(80);
      line-height: px(100);
    }
    @media(max-width:1024px){
      font-size: px(48);
      line-height: px(58);
    }
    @media(max-width:576px){
      font-size: px(42);
      line-height: px(50);
    }
  }

  &.light-title{
    color: $baseWhite;
    &::before{
      color: $blackBg;
      -webkit-text-stroke: 1px #545454;
    }
  }
}