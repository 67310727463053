.form{
  &-list{
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @media(max-width:1700px){
      width: calc(100% + 50px);
      margin-left: -25px;
      margin-right: -25px;
    }
    @media(max-width:1500px){
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
    @media(max-width:1024px){
      width: 100%;
    }
  }

  &-field {
    width: 33.3333333333333333%;
    padding: 0 px(30);
    margin-bottom: px(60);
    position: relative;
    display: flex;
    transition: .3s;
    flex-direction: column;
    justify-content: flex-start;
    @media(max-width:1700px){
      padding: 0 px(25);
      margin-bottom: px(50);
    }
    @media(max-width:1500px){
      padding: 0 px(20);
      margin-bottom: px(40);
    }
    @media(max-width:1024px){
      width: 100%;
      padding: 0;
      margin-bottom: px(30);
    }
    &.full-width{
      width: 100%;
    }
  }

  &__field-require  {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(20);
    line-height: px(24);
    color: $baseRed;
    transition: .3s;
    position: absolute;
    bottom: 0;
    transform: translateY(115%);
    opacity: 0;
    visibility: hidden;
    @media(max-width:1700px){
      font-size: px(18);
      line-height: px(22);
    }
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(20);
    }
    @media(max-width:1024px){
      font-size: px(14);
      line-height: px(18);
    }
  }

  &-field__name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: px(20);
    line-height: px(24);
    color: $baseBlack;
    margin-bottom: px(20);
    @media(max-width:1700px){
      font-size: px(18);
      line-height: px(22);
      margin-bottom: px(18);
    }
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(20);
      margin-bottom: px(16);
    }
    @media(max-width:1024px){
      margin-bottom: px(14);
    }
  }

  &-item {
    width: 100%;
    position: relative;
    transition: .3s;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.error, &.error__email{
      .form__field-input{
        &::after{
          opacity: 1;
        }
      }
      input{
        color: $baseRed !important;
        &::placeholder{
          color: $baseRed !important;
        }
      }
      .form__field-require{
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__field-input {
    width: 100%;
    position: relative;
    z-index: 1;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    padding: 2px;
    z-index: 2;
    border-radius: px(10);
    margin-top: auto;
    @media(max-width:1024px){
      border-radius: px(5);
      padding: px(1);
    }
    &::after{
      background: $baseRed;
      position: absolute;
      z-index: -1;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .3s;
      border-radius: px(9);
      @media(max-width:1024px){
        border-radius: px(5);
      }
    }
  }

  &__input{
    width: 100%;
    position: relative;
    min-height: px(60);
    transition: .3s;
    padding: px(10) px(20);
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    background: $baseWhite;
    border-radius: px(8);
    font-style: normal;
    font-weight: 400;
    font-size: px(20);
    line-height: px(24);
    color: $baseBlack;
    @media(max-width:1700px){
      font-size: px(18);
      line-height: px(22);
    }
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(20);
      min-height: px(50);
    }
    @media(max-width:1024px){
      border-radius: px(4);
      padding: px(7) px(14);
    }
    &::placeholder{
      color: #ACACAC;;
      transition: .3s;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

// intl
.iti{
  width: 100% !important;
}
.iti__selected-flag{
  padding-left: 15px;
  padding-right: 5px;
}
.iti__country-list{
  padding: 0;
  max-width: 800%;
  @media(max-width:1400px){
    max-width: 650%;
  }
  @media(max-width:1320px){
    max-width: 550%;
  }
  @media(max-width:1150px){
    max-width: 500%;
  }
  @media(max-width:1024px){
    min-width: 260px;
    max-width: calc(100% - 30px);
    margin-left: -16px;
    max-height: 150px;
  }
}
.iti__divider{
  padding: 0;
  margin: 0;
}
// intl end