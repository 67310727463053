.footer {
  width: 100%;
  position: relative;
  padding: px(120) px(30);
  padding-bottom: px(70);
  @media(max-width:1700px){
    padding: px(100) px(30);
    padding-bottom: px(60);
  }
  @media(max-width:1500px){
    padding: px(80) px(30);
    padding-bottom: px(50);
  }
  @media(max-width:1200px){
    padding: px(60) px(30);
    padding-bottom: px(35);
  }
  @media(max-width:1024px){
    padding: px(40) px(15);
    padding-bottom: px(30);
  }
  &::before{
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background: linear-gradient(267.06deg, #B75BFF -15.88%, #689AFF 122.47%);
  }

  &-top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(110);
    grid-column-gap:px(40);
    @media(max-width:1700px){
      margin-bottom: px(80);
      grid-column-gap:px(30);
    }
    @media(max-width:1500px){
      margin-bottom: px(60);
      grid-column-gap:px(40);
    }
    @media(max-width:1024px){
      flex-direction: column;
      justify-content: flex-start;
      grid-row-gap:px(40);
    }
  }

  &__logo{
    width: px(120);
    min-width: px(120);
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:1700px){
      width: px(100);
      min-width: px(100);
    }
    @media(max-width:1500px){
      width: px(90);
      min-width: px(90);
    }
    @media(max-width:1024px){
      width: px(80);
      min-width: px(80);
    }
    @media(max-width:576px){
      width: px(65);
      min-width: px(65);
    }
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    max-width: px(740);
    gap: px(20) px(30);
    @media(max-width:1700px){
      gap: px(15) px(20);
      max-width: px(680);
    }
    @media(max-width:1500px){
      max-width: px(570);
    }
    @media(max-width:1400px){
      max-width: px(520);
    }
    @media(max-width:1300px){
      max-width: px(500);
      gap: px(15) px(30);
    }
    @media(max-width:1100px){
      max-width: px(500);
      gap: px(10) px(20);
    }
    @media(max-width:1024px){
      max-width: none;
      gap: px(15) px(20);
    }
    li{
      width: calc(100% / 3 - 20px);
      @media(max-width:1700px){
        width: calc(100% / 3 - 14px);
      }
      @media(max-width:1300px){
        width: calc(100% / 2 - 15px);
      }
      @media(max-width:1100px){
        width: calc(100% / 2 - 10px);
      }
      @media(max-width:1024px){
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__link{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    color: $baseBlack;
    transition: .3s;
    z-index: 1;
    text-transform: uppercase;
    @media(max-width:1700px){
      font-size: px(15);
      line-height: px(18);
    }
    @media(max-width:1600px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1500px){
      font-size: px(13);
      line-height: px(17);
    }
    @media(max-width:1400px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1250px){
      font-size: px(13);
      line-height: px(17);
    }
    @media(max-width:1180px){
      font-size: px(12);
      line-height: px(16);
    }
    @media(max-width:1070px){
      text-transform: none;
      font-size: px(13);
    }
    @media(max-width:1024px){
      font-size: px(16);
      line-height: px(20);
      font-weight: 500;
      text-align: center;
    }
    &.active{
      @media(min-width: 1025px){
        color: $basePurple;
        &::after{
          width: 100%;
        }
      }

    }
    &:hover{
      @media(min-width: 1025px){
        color: $basePurple;
      }
    }
  }

  &__partners {
    display: inline-flex;
    align-items: center;
    gap:px(20)
  }

  &__partner{
    width: px(120);
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
    &.footer__google{
      width: px(100);
    }
  }

  &__contacts {
    min-width: fit-content;
    @media(max-width:1024px){
      min-width: auto;
    }
    li{
      line-height: 1;
      display: flex;
      align-items: center;
      margin-bottom: px(12);
      &:last-child{
        margin-bottom: 0;
      }
      @media(max-width:1700px){
        margin-bottom: px(10);
      }
      @media(max-width:1024px){
        margin-bottom: px(15);
        justify-content: center;
      }
    }
  }
  &-contact-link{
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(20);
    line-height: px(24);
    color: $baseBlack;
    transition: .3s;
    display: inline-flex;
    width: fit-content;
    @media(max-width:1700px){
      font-size: px(15);
      line-height: px(18);
    }
    @media(max-width:1600px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1024px){
      font-size: px(16);
      line-height: px(20);
      font-weight: 500;
    }
    .icon{
      max-width: px(24);
      max-height: px(24);
      min-width: px(24);
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      transition: .3s;
      fill: $basePurple;
      margin-right: px(15);
      &.stroke{
        fill: transparent;
        stroke: $basePurple;
        stroke-width: px(2);
      }
      @media(max-width:1700px){
        max-width: px(20);
        max-height: px(20);
        min-width: px(20);
      }
      @media(max-width:1600px){
        margin-right: px(10);
      }
    }
    &:hover{
      @media(min-width: 1025px){
        color: $baseBlue;
        .icon{
          fill: $baseBlue;
          &.stroke{
            fill: transparent;
            stroke: $baseBlue;
          }
        }
      }
    }
  }


  &-bottom{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
