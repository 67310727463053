.reviews{
  &-section{
    padding-bottom: px(140);
    @media(max-width:1700px){
      padding-bottom: px(110);
    }
    @media(max-width:1500px){
      padding-bottom: px(80);
    }
    @media(max-width:1024px){
      padding-bottom: px(50);
    }
  }

  &-section__title{
    margin-bottom: px(170);
    @media(max-width:1700px){
      margin-bottom: px(150);
    }
    @media(max-width:1500px){
      margin-bottom: px(125);
    }
    @media(max-width:1300px){
      margin-bottom: px(95);
    }
    @media(max-width:1024px){
      margin-bottom: px(50);
    }
  }

  &-box{
    width: 100%;
    max-width: px(1400);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  &-column{
    width: 100%;
    position: relative;
    margin-bottom: px(78);
    @media(max-width:1700px){
      margin-bottom: px(70);
    }
    @media(max-width:1500px){
      margin-bottom: px(60);
    }
    @media(max-width:1024px){
      margin-bottom: px(40);
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  &-column__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: px(32);
    line-height: px(36);
    text-transform: uppercase;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(58);
    @media(max-width:1700px){
      font-size: px(30);
      line-height: px(34);
      margin-bottom: px(50);
    }
    @media(max-width:1500px){
      font-size: px(26);
      line-height: px(30);
      margin-bottom: px(40);
    }
    @media(max-width:1024px){
      font-size: px(22);
      line-height: px(28);
      margin-bottom: px(20);
    }
    @media(max-width:576px){
      font-size: px(20);
      line-height: px(24);
    }

    &.clutch-reviews__title{
      @media(min-width: 1101px){
        text-align: center;
      }
    }
  }

  &-clutch{
    width: 100%;
    position: relative;
    &.active{
      .reviews-clutch-content::after{
        opacity: 0;
        visibility: hidden;
      }
      .reviews-clutch-box{
        aspect-ratio: unset;
      }
    }
  }

  &-clutch-content{
    width: 100%;
    max-width: px(1020);
    position: relative;
    margin: 0 auto;
    &::after{
      width: 100%;
      position: absolute;
      z-index: 1;
      transition: .3s;
      bottom: 0;
      left: 0;
      height: px(166);
      pointer-events: none;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #343434 100%);
      content: '';
    }
  }

  &-clutch-box{
    width: 100%;
    position: relative;
    aspect-ratio: 1.29739777 / 1;
    overflow: hidden;
    border-radius: px(10);
    transition: .3s;
    @media(max-width:1024px){
      aspect-ratio: 1 / 1;
    }
    img{
      width: 100%;
      pointer-events: none;
      object-fit: contain;
    }
  }

  &-clutch-more__btn{
    width: px(60);
    position: relative;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @media(max-width:1500px){
      width: px(50);
      margin-top: px(7);
    }
    @media(max-width:1024px){
      width: px(40);
      margin-top: px(5);
    }
    @media(max-width:576px){
      width: px(35);
    }
    img{
      width: 100%;
      object-fit: contain;
      transition: .3s;
    }
    &:hover{
      @media(min-width: 1025px){
        transform: translateY(5px);
      }
    }
    &.active{
      opacity: 0;
      visibility: hidden;
      height: 0;
    }
  }

  // reviews list
  &-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: px(20);
  }

  &-card{
    width: calc(100% / 2 - 10px);
    position: relative;
    border-radius: px(10);
    background: $baseWhite;
    display: flex;
    justify-content: space-between;
    @media(max-width:1150px){
      width: 100%;
    }
    @media(max-width:576px){
      flex-direction: column;
    }
  }

  &-card__content{
    width: 65%;
    padding: px(24);
    border-right: 1px solid #E4EFF3;
    overflow: hidden;
    @media(max-width:1500px){
      padding: px(20);
      width: 60%;
    }
    @media(max-width:576px){
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #E4EFF3;
    }
  }
  &-card__sidebar{
    padding: px(24);
    width: 35%;
    overflow: hidden;
    @media(max-width:1500px){
      padding: px(20);
      width: 40%;
    }
    @media(max-width:576px){
      width: 100%;
    }
  }

  &-card__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: px(10);
    margin-bottom: px(24);
    @media(max-width:1500px){
      margin-bottom: px(20);
    }
    @media(max-width:1024px){
      margin-bottom: px(15);
    }
  }

  &-card__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(34);
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #6B7A7E;
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(30);
    }
    @media(max-width:1024px){
      font-size: px(14);
      line-height: px(24);
    }
  }

  &-card__rating{
    display: inline-flex;
    align-items: center;
  }

  &-card__rating-value{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(25);
    line-height: px(34);
    text-align: center;
    color: $baseBlack;
    margin-right: px(11);
    @media(max-width:1500px){
      font-size: px(22);
      line-height: px(30);
      margin-right: px(8);
    }
    @media(max-width:1500px){
      font-size: px(18);
      line-height: px(24);
      margin-right: px(8);
    }
  }

  &-card__rating-stars {
    display: inline-flex;
    align-items: center;
    margin-top: -1px;
  }

  &-card__star {
    width: px(20);
    min-width: px(20);
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:1500px){
      width: px(18);
      min-width: px(18);
    }
    @media(max-width:1024px){
      width: px(16);
      min-width: px(16);
    }
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }

  &-card__date{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(34);
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #6B7A7E;
    margin-top: px(24);
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(30);
    }
    @media(max-width:1024px){
      font-size: px(14);
      line-height: px(24);
      margin-top: px(20);
    }
  }

  &-card__feedback{
    width: 100%;
    position: relative;
  }

  &-card__feedback-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(20);
    line-height: px(34);
    color: $baseBlack;
    margin-bottom: px(12);
    @media(max-width:1500px){
      font-size: px(18);
      line-height: px(30);
      margin-bottom: px(10);
    }
    @media(max-width:1024px){
      font-size: px(16);
      line-height: px(24);
    }
  }

  &-card__feedback-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(24);
    color: #6B7A7E;
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(20);  
    }
    @media(max-width:1024px){
      font-size: px(14);
      line-height: px(18);  
    }
  }

  &-card__profile {
    padding-bottom: px(24);
    position: relative;
    width: 100%;
    @media(max-width:1500px){
      padding-bottom: px(20); 
    }
    &::after{
      content: '';
      background: #E4EFF3;
      position: absolute;
      left: px(-24);
      bottom: 0;
      height: px(1);
      width: px(196);
      @media(max-width:1500px){
        left: px(-20);
      }
      @media(max-width:1150px){
        width: calc(100% + 40px);
      }
    }
  }

  &-card__job{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(20);
    line-height: px(30);
    color: $baseBlack;
    margin-bottom: px(24);
    @media(max-width:1500px){
      font-size: px(18);
      line-height: px(26);
      margin-bottom: px(20);
    }
    @media(max-width:1024px){
      font-size: px(16);
      line-height: px(22);
      margin-bottom: px(15);
    }
  }

  &-card__user{
    display: flex;
    align-items: center;
  }

  &-card__usericon {
    width: px(76);
    min-width: px(76);
    aspect-ratio: 1 / 1;
    margin-right: px(12);
    @media(max-width:1500px){
      width: px(60);
      min-width: px(60);
      margin-right: px(10);
    }
    @media(max-width:1024px){
      width: px(45);
      min-width: px(45);
    }
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }

  &-card__username{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(24);
    color: #6B7A7E;
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(20);
    }
    @media(max-width:1024px){
      font-size: px(14);
      line-height: px(18);
    }
  }

  &-card__categories {
    width: 100%;
    padding-top: px(24);
    @media(max-width:1500px){
      padding-top: px(20); 
    }
  }

  &-card__categorie__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: px(3);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(28);
    color: #6B7A7E;
    @media(max-width:1500px){
      font-size: px(16);
      line-height: px(24);
      margin-bottom: px(5);
    }
    @media(max-width:1024px){
      font-size: px(14);
      line-height: px(20);
      margin-bottom: px(5);
    }
    &:last-child{
      margin-bottom: 0;
    }
    .symbol {
      width: px(20);
      min-width: px(20);
      margin-right: px(10);
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: px(4);
      .icon{
        width: px(20);
        height: px(20);
        object-fit: contain;
        fill: $basePurple;
        &.stroke{
          fill: transparent;
          stroke: $basePurple;
          stroke-width: 1.2px;
        }
      }
      @media(max-width:1500px){
        margin-top: px(2);
      }
      @media(max-width:1024px){
        margin-top: 0;
        margin-right: px(7);
      }
    }
    
  }
}