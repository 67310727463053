.pulse-circle {
  width: px(556);
  aspect-ratio: 1/1;
  position: absolute;
  z-index: -1;
  &,
  & > * {
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #B75BFF;
  }

  &__large {
    width: 100%;
    animation: pulseCircle 7.5s ease-in-out infinite backwards;
    border-style: dashed;
  }
  &__medium {
    width: 66.18705%;
    animation: pulseCircle 7.5s ease-in-out -1s infinite backwards;
  }
  &__small {
    width: 30%;
    animation: pulseCircle 7.5s ease-in-out -2s infinite backwards;
    border-style: dashed;
  }
}

@keyframes pulseCircle {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(.75);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  75% {
    transform: translate(-50%, -50%) scale(.75);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}