[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("truetype"), url("../fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.eot");
  src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLight.eot");
  src: url("../fonts/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLight.woff") format("woff"), url("../fonts/Montserrat-ExtraLight.ttf") format("truetype"), url("../fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.eot");
  src: url("../fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff"), url("../fonts/Montserrat-ExtraBold.ttf") format("truetype"), url("../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BlackItalic.eot");
  src: url("../fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("../fonts/Montserrat-BlackItalic.woff") format("woff"), url("../fonts/Montserrat-BlackItalic.ttf") format("truetype"), url("../fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("../fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBoldItalic.woff") format("woff"), url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"), url("../fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Italic.eot");
  src: url("../fonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Italic.woff2") format("woff2"), url("../fonts/Montserrat-Italic.woff") format("woff"), url("../fonts/Montserrat-Italic.ttf") format("truetype"), url("../fonts/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.eot");
  src: url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff"), url("../fonts/Montserrat-Black.ttf") format("truetype"), url("../fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-LightItalic.eot");
  src: url("../fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-LightItalic.woff2") format("woff2"), url("../fonts/Montserrat-LightItalic.woff") format("woff"), url("../fonts/Montserrat-LightItalic.ttf") format("truetype"), url("../fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.eot");
  src: url("../fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-BoldItalic.woff") format("woff"), url("../fonts/Montserrat-BoldItalic.ttf") format("truetype"), url("../fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLightItalic.eot");
  src: url("../fonts/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLightItalic.woff") format("woff"), url("../fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"), url("../fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ThinItalic.eot");
  src: url("../fonts/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("../fonts/Montserrat-ThinItalic.woff") format("woff"), url("../fonts/Montserrat-ThinItalic.ttf") format("truetype"), url("../fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.eot");
  src: url("../fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("../fonts/Montserrat-MediumItalic.woff") format("woff"), url("../fonts/Montserrat-MediumItalic.ttf") format("truetype"), url("../fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Thin.eot");
  src: url("../fonts/Montserrat-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Thin.woff2") format("woff2"), url("../fonts/Montserrat-Thin.woff") format("woff"), url("../fonts/Montserrat-Thin.ttf") format("truetype"), url("../fonts/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBoldItalic.eot");
  src: url("../fonts/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-SemiBoldItalic.woff") format("woff"), url("../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"), url("../fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/**
 * Functions for ViewPort Calculating
============
  SCSS
  .selector {
    font-size: px(18);
    @include md
      font-size: pxm(14);
    @include xs
      font-size: pxs(12);
  }

  CSS RESULT
  .selector {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .selector {
      font-size: 14px;
    }
  }
  @media (max-width: 320px) {
    .selector {
      font-size: 12px;
    }
  }
============
*/
/**
  function VA => use for calculate letter-spacing from photoshop
============
  SCSS
  .selector {
    letter-spacing: VA(120, 14);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
  $unit => VA value in photoshop
  $font_size => how px font-size in photoshop ||| default 16
  $return px | em ||| default px
 */
* {
  padding: 0;
  margin: 0;
  outline: none;
}

.lock {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}

.important__none {
  display: none !important;
}

.disable-display {
  display: none !important;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  line-height: 1.3;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #252525;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: #252525;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  font: inherit;
  outline: none !important;
  border: none;
  background: transparent;
  border-radius: 0;
  appearance: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.content {
  flex: 1 0 auto;
}

.global-section {
  width: 100%;
  position: relative;
  padding: 96px 30px;
  z-index: 1;
}
@media (max-width: 1700px) {
  .global-section {
    padding: 80px 30px;
  }
}
@media (max-width: 1500px) {
  .global-section {
    padding: 70px 30px;
  }
}
@media (max-width: 1024px) {
  .global-section {
    padding: 40px 15px;
  }
}
.global-section.bg-section-dark {
  background: #343434;
}

.container {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
}

.global-socials {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 20px;
  justify-content: center;
}

.global-social__item {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.global-social__item .icon {
  width: inherit;
  height: inherit;
  object-fit: contain;
  fill: #BC65FF;
  transition: 0.3s;
}
@media (max-width: 1700px) {
  .global-social__item {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 1024px) {
  .global-social__item {
    width: 22px;
    height: 22px;
  }
}
@media (min-width: 1025px) {
  .global-social__item:hover .icon {
    fill: #75AFF9;
  }
}

.swiper-container {
  overflow: hidden;
}

button {
  cursor: pointer;
}

.global-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  text-align: center;
  color: #252525;
  margin-bottom: 135px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1700px) {
  .global-title {
    font-size: 54px;
    line-height: 66px;
    margin-bottom: 113px;
  }
}
@media (max-width: 1500px) {
  .global-title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 80px;
  }
}
@media (max-width: 1300px) {
  .global-title {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 70px;
  }
}
@media (max-width: 1024px) {
  .global-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }
}
.global-title::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  content: attr(data-title);
  font-family: sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 120px;
  line-height: 146px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  -webkit-text-stroke: 1px #DDDDDD;
  white-space: nowrap;
}
@media (max-width: 1700px) {
  .global-title::before {
    font-size: 110px;
    line-height: 130px;
  }
}
@media (max-width: 1500px) {
  .global-title::before {
    font-size: 90px;
    line-height: 110px;
  }
}
@media (max-width: 1300px) {
  .global-title::before {
    font-size: 80px;
    line-height: 100px;
  }
}
@media (max-width: 1024px) {
  .global-title::before {
    font-size: 48px;
    line-height: 58px;
  }
}
@media (max-width: 576px) {
  .global-title::before {
    font-size: 42px;
    line-height: 50px;
  }
}
.global-title.light-title {
  color: #fff;
}
.global-title.light-title::before {
  color: #343434;
  -webkit-text-stroke: 1px #545454;
}

.global-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  min-height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 335px;
  padding: 15px;
  overflow: hidden;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  border-radius: 10px;
  color: #fff;
  transition: 0.3s;
}
.global-btn::after {
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  position: absolute;
  opacity: 0;
  transition: 0.3s;
}
@media (min-width: 1025px) {
  .global-btn:hover::after {
    opacity: 1;
  }
}
@media (max-width: 1700px) {
  .global-btn {
    font-size: 18px;
    line-height: 22px;
    min-height: 60px;
  }
}
@media (max-width: 1500px) {
  .global-btn {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .global-btn {
    font-size: 14px;
    line-height: 18px;
    min-height: 50px;
    min-width: 280px;
    border-radius: 6px;
  }
}
@media (max-width: 450px) {
  .global-btn {
    min-width: 100%;
  }
}

.white-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  min-height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 335px;
  padding: 15px;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
  color: #252525;
  transition: 0.3s;
}
@media (min-width: 1025px) {
  .white-btn:hover {
    color: #fff;
    background: #343434;
  }
}
@media (max-width: 1700px) {
  .white-btn {
    font-size: 18px;
    line-height: 22px;
    min-height: 60px;
  }
}
@media (max-width: 1500px) {
  .white-btn {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .white-btn {
    font-size: 14px;
    line-height: 18px;
    min-height: 50px;
    min-width: 280px;
    border-radius: 6px;
  }
}
@media (max-width: 450px) {
  .white-btn {
    min-width: 100%;
  }
}

.global-play-button {
  width: 140px;
  aspect-ratio: 1/1.25714286;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.3s;
}
@media (max-width: 1700px) {
  .global-play-button {
    width: 125px;
  }
}
@media (max-width: 1500px) {
  .global-play-button {
    width: 110px;
  }
}
@media (max-width: 1300px) {
  .global-play-button {
    width: 90px;
  }
}
@media (max-width: 1024px) {
  .global-play-button {
    width: 70px;
  }
}
@media (max-width: 576px) {
  .global-play-button {
    width: 50px;
  }
}
.global-play-button img {
  width: 100%;
  pointer-events: none;
  object-fit: contain;
}
@media (min-width: 1025px) {
  .global-play-button:hover {
    top: 49.5%;
  }
}
.global-play-button.hide {
  opacity: 0;
  pointer-events: none;
}

.footer {
  width: 100%;
  position: relative;
  padding: 120px 30px;
  padding-bottom: 70px;
}
@media (max-width: 1700px) {
  .footer {
    padding: 100px 30px;
    padding-bottom: 60px;
  }
}
@media (max-width: 1500px) {
  .footer {
    padding: 80px 30px;
    padding-bottom: 50px;
  }
}
@media (max-width: 1200px) {
  .footer {
    padding: 60px 30px;
    padding-bottom: 35px;
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 40px 15px;
    padding-bottom: 30px;
  }
}
.footer::before {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: linear-gradient(267.06deg, #B75BFF -15.88%, #689AFF 122.47%);
}
.footer-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 110px;
  grid-column-gap: 40px;
}
@media (max-width: 1700px) {
  .footer-top {
    margin-bottom: 80px;
    grid-column-gap: 30px;
  }
}
@media (max-width: 1500px) {
  .footer-top {
    margin-bottom: 60px;
    grid-column-gap: 40px;
  }
}
@media (max-width: 1024px) {
  .footer-top {
    flex-direction: column;
    justify-content: flex-start;
    grid-row-gap: 40px;
  }
}
.footer__logo {
  width: 120px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1700px) {
  .footer__logo {
    width: 100px;
    min-width: 100px;
  }
}
@media (max-width: 1500px) {
  .footer__logo {
    width: 90px;
    min-width: 90px;
  }
}
@media (max-width: 1024px) {
  .footer__logo {
    width: 80px;
    min-width: 80px;
  }
}
@media (max-width: 576px) {
  .footer__logo {
    width: 65px;
    min-width: 65px;
  }
}
.footer__logo img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
.footer__links {
  display: flex;
  flex-wrap: wrap;
  max-width: 740px;
  gap: 20px 30px;
}
@media (max-width: 1700px) {
  .footer__links {
    gap: 15px 20px;
    max-width: 680px;
  }
}
@media (max-width: 1500px) {
  .footer__links {
    max-width: 570px;
  }
}
@media (max-width: 1400px) {
  .footer__links {
    max-width: 520px;
  }
}
@media (max-width: 1300px) {
  .footer__links {
    max-width: 500px;
    gap: 15px 30px;
  }
}
@media (max-width: 1100px) {
  .footer__links {
    max-width: 500px;
    gap: 10px 20px;
  }
}
@media (max-width: 1024px) {
  .footer__links {
    max-width: none;
    gap: 15px 20px;
  }
}
.footer__links li {
  width: calc(33.3333333333% - 20px);
}
@media (max-width: 1700px) {
  .footer__links li {
    width: calc(33.3333333333% - 14px);
  }
}
@media (max-width: 1300px) {
  .footer__links li {
    width: calc(50% - 15px);
  }
}
@media (max-width: 1100px) {
  .footer__links li {
    width: calc(50% - 10px);
  }
}
@media (max-width: 1024px) {
  .footer__links li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  transition: 0.3s;
  z-index: 1;
  text-transform: uppercase;
}
@media (max-width: 1700px) {
  .footer__link {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (max-width: 1600px) {
  .footer__link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1500px) {
  .footer__link {
    font-size: 13px;
    line-height: 17px;
  }
}
@media (max-width: 1400px) {
  .footer__link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1250px) {
  .footer__link {
    font-size: 13px;
    line-height: 17px;
  }
}
@media (max-width: 1180px) {
  .footer__link {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 1070px) {
  .footer__link {
    text-transform: none;
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .footer__link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
  }
}
@media (min-width: 1025px) {
  .footer__link.active {
    color: #BC65FF;
  }
  .footer__link.active::after {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  .footer__link:hover {
    color: #BC65FF;
  }
}
.footer__partners {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}
.footer__partner {
  width: 120px;
}
.footer__partner img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
.footer__partner.footer__google {
  width: 100px;
}
.footer__contacts {
  min-width: fit-content;
}
@media (max-width: 1024px) {
  .footer__contacts {
    min-width: auto;
  }
}
.footer__contacts li {
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.footer__contacts li:last-child {
  margin-bottom: 0;
}
@media (max-width: 1700px) {
  .footer__contacts li {
    margin-bottom: 10px;
  }
}
@media (max-width: 1024px) {
  .footer__contacts li {
    margin-bottom: 15px;
    justify-content: center;
  }
}
.footer-contact-link {
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #252525;
  transition: 0.3s;
  display: inline-flex;
  width: fit-content;
}
@media (max-width: 1700px) {
  .footer-contact-link {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (max-width: 1600px) {
  .footer-contact-link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1024px) {
  .footer-contact-link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
}
.footer-contact-link .icon {
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  transition: 0.3s;
  fill: #BC65FF;
  margin-right: 15px;
}
.footer-contact-link .icon.stroke {
  fill: transparent;
  stroke: #BC65FF;
  stroke-width: 2px;
}
@media (max-width: 1700px) {
  .footer-contact-link .icon {
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
  }
}
@media (max-width: 1600px) {
  .footer-contact-link .icon {
    margin-right: 10px;
  }
}
@media (min-width: 1025px) {
  .footer-contact-link:hover {
    color: #75AFF9;
  }
  .footer-contact-link:hover .icon {
    fill: #75AFF9;
  }
  .footer-contact-link:hover .icon.stroke {
    fill: transparent;
    stroke: #75AFF9;
  }
}
.footer-bottom {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pulse-circle {
  width: 556px;
  aspect-ratio: 1/1;
  position: absolute;
  z-index: -1;
}
.pulse-circle, .pulse-circle > * {
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.pulse-circle > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #B75BFF;
}
.pulse-circle__large {
  width: 100%;
  animation: pulseCircle 7.5s ease-in-out infinite backwards;
  border-style: dashed;
}
.pulse-circle__medium {
  width: 66.18705%;
  animation: pulseCircle 7.5s ease-in-out -1s infinite backwards;
}
.pulse-circle__small {
  width: 30%;
  animation: pulseCircle 7.5s ease-in-out -2s infinite backwards;
  border-style: dashed;
}

@keyframes pulseCircle {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(0.75);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  75% {
    transform: translate(-50%, -50%) scale(0.75);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  background-color: #fff;
  padding: 10px 30px;
  box-shadow: 0px 9px 25px rgba(96, 95, 95, 0.06);
  min-height: 80px;
}
@media (max-width: 1024px) {
  .header {
    padding: 10px 15px;
    min-height: 70px;
  }
}
.header__container {
  width: 100%;
  max-width: 1680px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 59px;
  width: 59px;
}
.header__logo.mobile-logo {
  display: none;
}
@media (max-width: 1024px) {
  .header__logo.mobile-logo {
    display: inline-flex;
  }
}
@media (max-width: 1200px) {
  .header__logo {
    min-width: 50px;
    width: 50px;
  }
}
@media (max-width: 1024px) {
  .header__logo {
    min-width: 50px;
    width: 50px;
    display: none;
  }
}
.header__logo img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
.header__burger {
  display: none;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: 30px;
  transition: 0.3s;
  background: transparent;
  border: none;
  outline: none;
}
.header__burger .icon {
  fill: #252525;
  stroke: #252525;
  stroke-width: 0.5px;
  transition: 0.3s;
  max-width: 30px;
  max-height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.header__burger .icon-close-burger {
  opacity: 0;
  visibility: hidden;
  max-height: 20px;
  stroke-width: 0.7px;
}
.header__burger.active .icon-close-burger {
  opacity: 1;
  visibility: visible;
}
.header__burger.active .icon-burger {
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1024px) {
  .header__burger {
    display: flex;
  }
}
.header__menu {
  display: inline-flex;
  align-items: center;
}
.header__menu li {
  margin: 0 14px;
}
@media (max-width: 1650px) {
  .header__menu li {
    margin: 0 10px;
  }
}
@media (max-width: 1500px) {
  .header__menu li {
    margin: 0 7px;
  }
}
@media (max-width: 1180px) {
  .header__menu li {
    margin: 0 5px;
  }
}
@media (max-width: 1024px) {
  .header__menu li {
    margin: 0;
    margin-bottom: 20px;
  }
}
.header__menu li:first-child {
  margin-left: 0;
}
.header__menu li:last-child {
  margin-right: 0;
}
@media (max-width: 1024px) {
  .header__menu li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .header__menu {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
}
.header__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  transition: 0.3s;
  z-index: 1;
  text-transform: uppercase;
}
@media (max-width: 1700px) {
  .header__link {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (max-width: 1600px) {
  .header__link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1500px) {
  .header__link {
    font-size: 13px;
    line-height: 17px;
  }
}
@media (max-width: 1400px) {
  .header__link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1250px) {
  .header__link {
    font-size: 13px;
    line-height: 17px;
  }
}
@media (max-width: 1180px) {
  .header__link {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 1070px) {
  .header__link {
    text-transform: none;
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .header__link {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
}
.header__link:after {
  width: 0%;
  height: 1px;
  background-color: #BC65FF;
  transition: 0.3s;
  position: absolute;
  left: 0;
  bottom: 1px;
  content: "";
}
@media (min-width: 1025px) {
  .header__link.active {
    color: #BC65FF;
  }
  .header__link.active::after {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  .header__link:hover {
    color: #BC65FF;
  }
  .header__link:hover::after {
    width: 100%;
  }
}
.header-contacts-box {
  width: fit-content;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .header-contacts-box {
    margin-top: auto;
  }
}
.header-contacts-box li {
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.header-contacts-box li:last-child {
  margin-bottom: 0;
}
@media (max-width: 1700px) {
  .header-contacts-box li {
    margin-bottom: 10px;
  }
}
@media (max-width: 1024px) {
  .header-contacts-box li {
    margin-bottom: 15px;
  }
}
.header-contact-link {
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  transition: 0.3s;
  display: inline-flex;
  width: fit-content;
}
@media (max-width: 1700px) {
  .header-contact-link {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (max-width: 1600px) {
  .header-contact-link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1400px) {
  .header-contact-link span {
    display: none;
  }
}
@media (max-width: 1024px) {
  .header-contact-link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
  .header-contact-link span {
    display: block;
  }
}
.header-contact-link .icon {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  transition: 0.3s;
  fill: #BC65FF;
  margin-right: 15px;
}
.header-contact-link .icon.stroke {
  fill: transparent;
  stroke: #BC65FF;
  stroke-width: 2px;
}
@media (max-width: 1600px) {
  .header-contact-link .icon {
    margin-right: 10px;
  }
}
@media (max-width: 1400px) {
  .header-contact-link .icon {
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .header-contact-link .icon {
    margin-right: 10px;
  }
}
@media (min-width: 1025px) {
  .header-contact-link:hover {
    color: #75AFF9;
  }
  .header-contact-link:hover .icon {
    fill: #75AFF9;
  }
  .header-contact-link:hover .icon.stroke {
    fill: transparent;
    stroke: #75AFF9;
  }
}
.header-mobile__menu-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  width: 100%;
}
@media (max-width: 1024px) {
  .header-mobile__menu-inner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
.header-mobile__menu {
  width: 100%;
}
@media (max-width: 1024px) {
  .header-mobile__menu {
    position: fixed;
    top: 70px;
    z-index: 10;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 70px);
    padding: 25px 15px;
    border-top: 1px solid #2B2B2B;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transform: translateX(110%);
    transition: 0.4s;
    transition-delay: 0.15s;
  }
  .header-mobile__menu.active {
    transform: translateX(0);
    transition-delay: 0;
  }
}

.overlay {
  position: fixed;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(2px);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backdrop-filter: blur(2px);
}
.overlay.active {
  opacity: 1;
  visibility: visible;
}

.form-list {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 1700px) {
  .form-list {
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media (max-width: 1500px) {
  .form-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1024px) {
  .form-list {
    width: 100%;
  }
}
.form-field {
  width: 33.3333333333%;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 1700px) {
  .form-field {
    padding: 0 25px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1500px) {
  .form-field {
    padding: 0 20px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .form-field {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
}
.form-field.full-width {
  width: 100%;
}
.form__field-require {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FF7D8D;
  transition: 0.3s;
  position: absolute;
  bottom: 0;
  transform: translateY(115%);
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1700px) {
  .form__field-require {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1500px) {
  .form__field-require {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .form__field-require {
    font-size: 14px;
    line-height: 18px;
  }
}
.form-field__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #252525;
  margin-bottom: 20px;
}
@media (max-width: 1700px) {
  .form-field__name {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 18px;
  }
}
@media (max-width: 1500px) {
  .form-field__name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}
@media (max-width: 1024px) {
  .form-field__name {
    margin-bottom: 14px;
  }
}
.form-item {
  width: 100%;
  position: relative;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-item.error .form__field-input::after, .form-item.error__email .form__field-input::after {
  opacity: 1;
}
.form-item.error input, .form-item.error__email input {
  color: #FF7D8D !important;
}
.form-item.error input::placeholder, .form-item.error__email input::placeholder {
  color: #FF7D8D !important;
}
.form-item.error .form__field-require, .form-item.error__email .form__field-require {
  opacity: 1;
  visibility: visible;
}
.form__field-input {
  width: 100%;
  position: relative;
  z-index: 1;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  padding: 2px;
  z-index: 2;
  border-radius: 10px;
  margin-top: auto;
}
@media (max-width: 1024px) {
  .form__field-input {
    border-radius: 5px;
    padding: 1px;
  }
}
.form__field-input::after {
  background: #FF7D8D;
  position: absolute;
  z-index: -1;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s;
  border-radius: 9px;
}
@media (max-width: 1024px) {
  .form__field-input::after {
    border-radius: 5px;
  }
}
.form__input {
  width: 100%;
  position: relative;
  min-height: 60px;
  transition: 0.3s;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  background: #fff;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #252525;
}
@media (max-width: 1700px) {
  .form__input {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1500px) {
  .form__input {
    font-size: 16px;
    line-height: 20px;
    min-height: 50px;
  }
}
@media (max-width: 1024px) {
  .form__input {
    border-radius: 4px;
    padding: 7px 14px;
  }
}
.form__input::placeholder {
  color: #ACACAC;
  transition: 0.3s;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

.iti {
  width: 100% !important;
}

.iti__selected-flag {
  padding-left: 15px;
  padding-right: 5px;
}

.iti__country-list {
  padding: 0;
  max-width: 800%;
}
@media (max-width: 1400px) {
  .iti__country-list {
    max-width: 650%;
  }
}
@media (max-width: 1320px) {
  .iti__country-list {
    max-width: 550%;
  }
}
@media (max-width: 1150px) {
  .iti__country-list {
    max-width: 500%;
  }
}
@media (max-width: 1024px) {
  .iti__country-list {
    min-width: 260px;
    max-width: calc(100% - 30px);
    margin-left: -16px;
    max-height: 150px;
  }
}

.iti__divider {
  padding: 0;
  margin: 0;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 140px;
  padding-bottom: 120px;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .hero-section {
    padding-top: 130px;
    padding-bottom: 100px;
  }
}
@media (max-width: 1024px) {
  .hero-section {
    min-height: 650px;
    padding-top: 110px;
    padding-bottom: 60px;
  }
}
@media (max-width: 700px) {
  .hero-section {
    padding-top: 110px;
    padding-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .hero-section {
    min-height: 500px;
    height: calc(100vh - 80px);
  }
}
@media (min-width: 1025px) and (min-height: 1011px) {
  .hero-section {
    min-height: 1010px;
  }
}
.hero-section::before {
  width: 600px;
  aspect-ratio: 1/1;
  background: rgba(183, 91, 255, 0.26);
  filter: blur(300px);
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: -22%;
}
@media (max-width: 1700px) {
  .hero-section::before {
    width: 500px;
    left: -18%;
  }
}
@media (max-width: 1500px) {
  .hero-section::before {
    width: 400px;
    left: -12%;
  }
}
@media (max-width: 1200px) {
  .hero-section::before {
    width: 350px;
    left: -5%;
  }
}
@media (max-width: 1024px) {
  .hero-section::before {
    display: none;
  }
}
.hero-section::after {
  width: 600px;
  aspect-ratio: 1/1;
  background: rgba(116, 171, 255, 0.3);
  filter: blur(300px);
  position: absolute;
  z-index: -1;
  content: "";
  bottom: -15%;
  right: -10%;
}
@media (max-width: 1700px) {
  .hero-section::after {
    width: 500px;
  }
}
@media (max-width: 1500px) {
  .hero-section::after {
    width: 400px;
    bottom: -5%;
  }
}
@media (max-width: 1200px) {
  .hero-section::after {
    width: 350px;
    bottom: -5%;
    right: -5%;
  }
}
@media (max-width: 1024px) {
  .hero-section::after {
    display: none;
  }
}
.hero-section .container {
  margin: auto;
}
.hero-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 750px;
  margin: 0 auto;
}
.hero-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 128px;
  line-height: 141.4%;
  text-transform: uppercase;
  background: linear-gradient(90deg, #B75BFF 18.78%, #689BFF 97.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 1700px) {
  .hero-title {
    font-size: 110px;
    margin-bottom: 30px;
    line-height: 130%;
  }
}
@media (max-width: 1500px) {
  .hero-title {
    font-size: 100px;
    margin-bottom: 20px;
    line-height: 120%;
  }
}
@media (max-width: 1024px) {
  .hero-title {
    font-size: 64px;
  }
}
@media (max-width: 576px) {
  .hero-title {
    font-size: 45px;
  }
}
.hero-subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 24px;
  color: #252525;
  max-width: 620px;
}
@media (max-width: 1700px) {
  .hero-subtitle {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1500px) {
  .hero-subtitle {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 1024px) {
  .hero-subtitle {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 576px) {
  .hero-subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 450px) {
  .hero-subtitle {
    max-width: 270px;
  }
}
.hero__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 50px;
  max-width: 535px;
}
@media (max-width: 1700px) {
  .hero__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .hero__text {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .hero__text {
    font-size: 14px;
    line-height: 20px;
  }
}
.hero-hide__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  text-transform: uppercase;
  color: rgba(104, 154, 255, 0.12);
  width: 100%;
  text-align: center;
  position: absolute;
  top: 80px;
  left: 0;
  pointer-events: none;
}
@media (max-width: 1700px) {
  .hero-hide__text {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 1500px) {
  .hero-hide__text {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 1200px) {
  .hero-hide__text {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 1024px) {
  .hero-hide__text {
    font-size: 20px;
    line-height: 28px;
    top: 70px;
  }
}
@media (max-width: 700px) {
  .hero-hide__text {
    display: none;
  }
}
@media (max-width: 340px) {
  .hero__btn {
    min-width: 100%;
  }
}
.hero__pulse-circle-1 {
  width: 1110px;
  top: -50%;
  left: -20%;
}
@media (max-width: 1700px) {
  .hero__pulse-circle-1 {
    width: 900px;
    top: -38%;
    left: -22%;
  }
}
@media (max-width: 1500px) {
  .hero__pulse-circle-1 {
    width: 700px;
    top: -28%;
    left: -22%;
  }
}
@media (max-width: 1200px) {
  .hero__pulse-circle-1 {
    width: 550px;
    top: -21%;
    left: -20%;
  }
}
@media (max-width: 1024px) {
  .hero__pulse-circle-1 {
    width: 400px;
  }
}
@media (max-width: 576px) {
  .hero__pulse-circle-1 {
    width: 300px;
    top: -15%;
  }
}
.hero__pulse-circle-2 {
  width: 1110px;
  bottom: -50%;
  right: -23%;
}
@media (max-width: 1700px) {
  .hero__pulse-circle-2 {
    width: 900px;
    bottom: -40%;
    right: -25%;
  }
}
@media (max-width: 1500px) {
  .hero__pulse-circle-2 {
    width: 700px;
    bottom: -33%;
    right: -22%;
  }
}
@media (max-width: 1200px) {
  .hero__pulse-circle-2 {
    width: 550px;
    bottom: -25%;
    right: -20%;
  }
}
@media (max-width: 1024px) {
  .hero__pulse-circle-2 {
    width: 400px;
  }
}
@media (max-width: 576px) {
  .hero__pulse-circle-2 {
    width: 300px;
  }
}

.website-section {
  padding-bottom: 110px;
}
@media (max-width: 1700px) {
  .website-section {
    padding-bottom: 80px;
  }
}
@media (max-width: 1500px) {
  .website-section {
    padding-bottom: 60px;
  }
}
@media (max-width: 1024px) {
  .website-section {
    padding-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .website-section {
    padding-bottom: 60px;
  }
}
.website-section__title {
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  .website-section__title {
    margin-bottom: 40px;
  }
}
.website-section__roadmap {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding-top: 106px;
}
@media (max-width: 1700px) {
  .website-section__roadmap {
    padding-top: 120px;
  }
}
@media (max-width: 1500px) {
  .website-section__roadmap {
    max-width: 1160px;
    padding-top: 82px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap {
    max-width: 920px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap {
    max-width: none;
    padding-top: 0;
  }
}
.website-section__roadmap-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 98px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(257.73deg, #B75BFF 7.81%, #689AFF 87.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 4px;
}
@media (max-width: 1700px) {
  .website-section__roadmap-title {
    font-size: 70px;
    line-height: 86px;
  }
}
@media (max-width: 1500px) {
  .website-section__roadmap-title {
    font-size: 60px;
    line-height: 73px;
    margin-bottom: 6px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-title {
    font-size: 50px;
    line-height: 64px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-title {
    margin-bottom: 4px;
    font-size: 44px;
    line-height: 52px;
  }
}
.website-section__roadmap-subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}
@media (max-width: 1700px) {
  .website-section__roadmap-subtitle {
    font-size: 22px;
    line-height: 26px;
  }
}
@media (max-width: 1500px) {
  .website-section__roadmap-subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-subtitle {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-subtitle {
    margin-bottom: 30px;
  }
}
.website-section__roadmap-list {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -82px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
}
@media (max-width: 1500px) {
  .website-section__roadmap-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -75px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -70px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-list {
    margin-top: 0;
  }
}
@media (max-width: 576px) {
  .website-section__roadmap-list {
    width: 100%;
    margin: 0;
  }
}
.website-section__roadmap-col {
  padding: 0 20px;
  width: 25%;
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  padding-bottom: 40px;
  pointer-events: all;
}
@media (max-width: 1500px) {
  .website-section__roadmap-col {
    padding: 0 15px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col {
    padding: 0 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-col {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
    width: 50%;
  }
}
@media (max-width: 576px) {
  .website-section__roadmap-col {
    padding: 0;
    width: 100%;
  }
  .website-section__roadmap-col:last-child {
    margin-bottom: 0;
  }
}
.website-section__roadmap-col:nth-child(4n-2) {
  align-items: flex-start;
  margin-top: 228px;
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-2) {
    margin-top: 190px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-2) {
    margin-top: 160px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-col:nth-child(4n-2) {
    margin-top: 0;
  }
}
.website-section__roadmap-col:nth-child(4n-2) .line {
  height: 85px;
  transform: translate(-50%, -125px) rotate(180deg);
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-2) .line {
    height: 50px;
    transform: translate(-50%, -70px) rotate(180deg);
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-2) .line {
    height: 40px;
    transform: translate(-50%, -60px) rotate(180deg);
  }
}
.website-section__roadmap-col:nth-child(4n-2) .line::before {
  width: 170px;
  transform: rotate(180deg) translateY(-50%);
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-2) .line::before {
    width: 130px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-2) .line::before {
    width: 98px;
  }
}
.website-section__roadmap-col:nth-child(4n-2) .line::after {
  right: 168px;
  top: 0;
  bottom: unset;
  height: 52px;
  transform: translateY(86px);
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-2) .line::after {
    height: 60px;
    right: 129px;
    transform: translateY(49px);
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-2) .line::after {
    height: 30px;
    right: 97px;
    transform: translateY(39px);
  }
}
.website-section__roadmap-col:nth-child(4n-1) {
  align-items: flex-end;
  margin-top: 228px;
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-1) {
    margin-top: 190px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-1) {
    margin-top: 160px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-col:nth-child(4n-1) {
    margin-top: 0;
  }
}
.website-section__roadmap-col:nth-child(4n-1) .line {
  height: 85px;
  transform: translate(-50%, -125px) rotate(180deg);
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-1) .line {
    height: 50px;
    transform: translate(-50%, -70px) rotate(180deg);
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-1) .line {
    height: 40px;
    transform: translate(-50%, -60px) rotate(180deg);
  }
}
.website-section__roadmap-col:nth-child(4n-1) .line::before {
  left: 0;
  right: unset;
  width: 170px;
  transform: translateY(50%);
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-1) .line::before {
    width: 130px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-1) .line::before {
    width: 98px;
  }
}
.website-section__roadmap-col:nth-child(4n-1) .line::after {
  right: -168px;
  height: 52px;
  bottom: 0;
  transform: rotate(0deg) translateY(100%);
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n-1) .line::after {
    height: 60px;
    right: -128px;
    transform: translateY(100%);
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n-1) .line::after {
    height: 30px;
    right: -96px;
    transform: translateY(100%);
  }
}
.website-section__roadmap-col:nth-child(4n) .line::before {
  right: 0;
  bottom: 0;
  height: 2px;
  position: absolute;
  width: 512px;
  transform: translateY(0%) translateX(100%);
  content: "";
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n) .line::before {
    width: 430px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n) .line::before {
    width: 330px;
  }
}
.website-section__roadmap-col:nth-child(4n) .line::after {
  background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
  left: 512px;
  right: unset;
  bottom: 0;
}
@media (max-width: 1500px) {
  .website-section__roadmap-col:nth-child(4n) .line::after {
    left: 430px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-col:nth-child(4n) .line::after {
    left: 330px;
  }
}
.website-section__roadmap-item {
  width: 100%;
  max-width: 300px;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  position: relative;
  box-shadow: 0px 9px 20px rgba(20, 20, 20, 0.4);
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 188px;
  max-height: 188px;
  z-index: 1;
  pointer-events: all;
}
@media (max-width: 1500px) {
  .website-section__roadmap-item {
    height: 160px;
    max-height: 160px;
    padding: 20px;
    border-radius: 16px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-item {
    height: 140px;
    max-height: 140px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-item {
    height: 100%;
    max-height: none;
    border-radius: 10px;
    max-width: none;
  }
}
.website-section__roadmap-item::before {
  width: 12px;
  height: 12px;
  background: #A767FF;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -52px);
}
@media (max-width: 1500px) {
  .website-section__roadmap-item::before {
    width: 10px;
    height: 10px;
    transform: translate(-50%, -30px);
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-item::before {
    transform: translate(-50%, -20px);
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-item::before {
    display: none;
  }
}
.website-section__roadmap-item::after {
  position: absolute;
  background: #343434;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
  border-radius: 19px;
  content: "";
  z-index: -1;
}
@media (max-width: 1500px) {
  .website-section__roadmap-item::after {
    border-radius: 15px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-item::after {
    border-radius: 9px;
  }
}
.website-section__roadmap-item .line {
  background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
  width: 2px;
  height: 165px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -206px) rotate(180deg);
  z-index: -1;
}
@media (max-width: 1500px) {
  .website-section__roadmap-item .line {
    height: 120px;
    transform: translate(-50%, -140px) rotate(180deg);
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-item .line {
    height: 100px;
    transform: translate(-50%, -120px) rotate(180deg);
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-item .line {
    display: none;
  }
}
.website-section__roadmap-item .line::before {
  background: linear-gradient(267.06deg, #B75BFF -15.88%, #689AFF 122.47%);
  right: 0;
  bottom: 0;
  height: 2px;
  position: absolute;
  width: 530px;
  transform: rotate(180deg) translateY(0);
  content: "";
}
@media (max-width: 1500px) {
  .website-section__roadmap-item .line::before {
    width: 425px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-item .line::before {
    width: 330px;
  }
}
.website-section__roadmap-item .line::after {
  background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
  right: 529.5px;
  bottom: 0;
  height: 90px;
  position: absolute;
  width: 2px;
  transform: rotate(180deg);
  content: "";
}
@media (max-width: 1500px) {
  .website-section__roadmap-item .line::after {
    height: 70px;
    right: 424px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-item .line::after {
    height: 60px;
    right: 329px;
  }
}
.website-section__roadmap-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FAFAFA;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1700px) {
  .website-section__roadmap-text {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 1500px) {
  .website-section__roadmap-text {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-text {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 1250px) {
  .website-section__roadmap-text {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 1024px) {
  .website-section__roadmap-text {
    -webkit-line-clamp: 100;
    font-size: 16px;
    line-height: 20px;
  }
}

.levels-section {
  padding-bottom: 270px;
}
@media (max-width: 1700px) {
  .levels-section {
    padding-bottom: 220px;
  }
}
@media (max-width: 1500px) {
  .levels-section {
    padding-bottom: 180px;
  }
}
@media (max-width: 1400px) {
  .levels-section {
    padding-bottom: 150px;
  }
}
@media (max-width: 1250px) {
  .levels-section {
    padding-bottom: 130px;
  }
}
@media (max-width: 1024px) {
  .levels-section {
    padding-bottom: 60px;
  }
}
@media (max-width: 576px) {
  .levels-section {
    padding-bottom: 50px;
  }
}
.levels-section__title {
  margin-bottom: 155px;
}
@media (max-width: 1700px) {
  .levels-section__title {
    margin-bottom: 135px;
  }
}
@media (max-width: 1500px) {
  .levels-section__title {
    margin-bottom: 115px;
  }
}
@media (max-width: 1350px) {
  .levels-section__title {
    margin-bottom: 100px;
  }
}
@media (max-width: 1024px) {
  .levels-section__title {
    margin-bottom: 45px;
  }
}
@media (max-width: 576px) {
  .levels-section__title {
    margin-bottom: 40px;
  }
}
.levels-roadmap {
  width: 100%;
  max-width: 1550px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  padding-right: 144px;
  padding-left: 72px;
}
@media (max-width: 1500px) {
  .levels-roadmap {
    padding-right: 124px;
    padding-left: 52px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap {
    padding-right: 94px;
    padding-left: 42px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap {
    padding: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    padding-top: 20px;
  }
}
@media (max-width: 576px) {
  .levels-roadmap {
    padding-bottom: 60px;
  }
}
.levels-roadmap__line {
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  height: 1px;
  width: calc(100% - 72px);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
@media (max-width: 1500px) {
  .levels-roadmap__line {
    width: calc(100% - 52px);
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__line {
    width: calc(100% - 42px);
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__line {
    width: 2px;
    height: calc(100% - 60px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
  }
}
@media (max-width: 576px) {
  .levels-roadmap__line {
    left: 30px;
    height: calc(100% - 40px);
  }
}
.levels-roadmap__line::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #AF60FF;
}
@media (max-width: 1500px) {
  .levels-roadmap__line::before {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__line::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.levels-roadmap__line::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #6C96FF;
}
@media (max-width: 1500px) {
  .levels-roadmap__line::after {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__line::after {
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.levels-roadmap__list {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
}
@media (max-width: 1350px) {
  .levels-roadmap__list {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__list {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
}
.levels-roadmap__item {
  padding: 180px 40px;
  position: relative;
  width: 14.2857142857%;
}
@media (max-width: 1700px) {
  .levels-roadmap__item {
    padding: 165px 40px;
  }
}
@media (max-width: 1500px) {
  .levels-roadmap__item {
    padding: 140px 40px;
  }
}
@media (max-width: 1350px) {
  .levels-roadmap__item {
    padding: 125px 30px;
  }
}
@media (max-width: 1350px) {
  .levels-roadmap__item {
    padding: 125px 30px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__item {
    padding: 125px 20px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .levels-roadmap__item {
    justify-content: flex-start;
    padding: 16px 0;
  }
}
.levels-roadmap__item:nth-child(2n) .levels-roadmap__info {
  top: unset;
  bottom: 0;
}
@media (max-width: 1024px) {
  .levels-roadmap__item:nth-child(2n) .levels-roadmap__info {
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
    left: unset;
    right: 0;
    text-align: left;
  }
}
.levels-roadmap__item:nth-child(2n) .levels-roadmap__step::before {
  top: unset;
  bottom: 0;
  transform: translateX(-50%) translateY(100%) rotate(180deg);
}
@media (max-width: 1024px) {
  .levels-roadmap__item:nth-child(2n) .levels-roadmap__step::before {
    bottom: unset;
    top: 50%;
    left: unset;
    right: 0;
    transform: translateX(100%) translateY(-50%) rotate(180deg);
  }
}
.levels-roadmap__item:nth-child(2n) .levels-roadmap__step::after {
  top: unset;
  bottom: 0;
  transform: translateX(-50%) translateY(90px);
}
@media (max-width: 1500px) {
  .levels-roadmap__item:nth-child(2n) .levels-roadmap__step::after {
    transform: translateX(-50%) translateY(65px);
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__item:nth-child(2n) .levels-roadmap__step::after {
    transform: translateX(-50%) translateY(50px);
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__item:nth-child(2n) .levels-roadmap__step::after {
    bottom: unset;
    top: 50%;
    left: unset;
    right: 0;
    transform: translateX(40px) translateY(-50%);
  }
}
@media (max-width: 576px) {
  .levels-roadmap__item:nth-child(2n) .levels-roadmap__step::after {
    transform: translateX(30px) translateY(-50%);
  }
}
.levels-roadmap__step {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: linear-gradient(180deg, #84ADFF 0%, #C175FD 100%);
  box-shadow: 0px 9px 25px rgba(96, 95, 95, 0.06);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  color: #FAFAFA;
  position: relative;
  z-index: 1;
}
@media (max-width: 1700px) {
  .levels-roadmap__step {
    font-size: 54px;
    line-height: 60px;
  }
}
@media (max-width: 1500px) {
  .levels-roadmap__step {
    font-size: 50px;
    line-height: 56px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__step {
    font-size: 44px;
    line-height: 50px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__step {
    font-size: 36px;
    line-height: 40px;
    max-width: 100px;
  }
}
@media (max-width: 700px) {
  .levels-roadmap__step {
    font-size: 30px;
    line-height: 36px;
    max-width: 80px;
  }
}
@media (max-width: 576px) {
  .levels-roadmap__step {
    font-size: 24px;
    line-height: 30px;
    max-width: 60px;
  }
}
.levels-roadmap__step::before {
  height: 90px;
  width: 2px;
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(-100%);
  background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
  z-index: -1;
}
@media (max-width: 1500px) {
  .levels-roadmap__step::before {
    height: 65px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__step::before {
    height: 50px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__step::before {
    width: 40px;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateX(-100%) translateY(-50%);
  }
}
@media (max-width: 576px) {
  .levels-roadmap__step::before {
    width: 30px;
    left: unset;
    right: 0;
    transform: translateX(100%) translateY(-50%) rotate(180deg);
  }
}
.levels-roadmap__step::after {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-90px);
  border-radius: 50%;
  background: #A767FF;
  content: "";
  z-index: -1;
}
@media (max-width: 1500px) {
  .levels-roadmap__step::after {
    width: 10px;
    height: 10px;
    transform: translateX(-50%) translateY(-65px);
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__step::after {
    transform: translateX(-50%) translateY(-50px);
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__step::after {
    top: 50%;
    left: 0;
    transform: translateX(-40px) translateY(-50%);
  }
}
@media (max-width: 576px) {
  .levels-roadmap__step::after {
    left: unset;
    right: 0;
    transform: translateX(30px) translateY(-50%);
  }
}
.levels-roadmap__info {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  padding-left: 47%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #252525;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1700px) {
  .levels-roadmap__info {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 1350px) {
  .levels-roadmap__info {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__info {
    -webkit-line-clamp: 3;
  }
}
@media (max-width: 1150px) {
  .levels-roadmap__info {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__info {
    font-size: 16px;
    line-height: 20px;
    padding-left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: calc(50% - 110px);
    text-align: right;
    -webkit-line-clamp: 4;
  }
}
@media (max-width: 700px) {
  .levels-roadmap__info {
    width: calc(50% - 95px);
  }
}
@media (max-width: 576px) {
  .levels-roadmap__info {
    width: calc(100% - 100px);
    left: unset;
    right: 0;
    text-align: left;
  }
}
.levels-roadmap__finish {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
}
.levels-roadmap__finish img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
@media (max-width: 1500px) {
  .levels-roadmap__finish {
    width: 38px;
  }
}
@media (max-width: 1250px) {
  .levels-roadmap__finish {
    width: 26px;
  }
}
@media (max-width: 1024px) {
  .levels-roadmap__finish {
    top: unset;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 36px;
  }
}
@media (max-width: 576px) {
  .levels-roadmap__finish {
    right: unset;
    left: 15px;
    transform: translateX(0);
    width: 30px;
  }
}

.reach-section {
  padding-bottom: 0;
}
@media (max-width: 1024px) {
  .reach-section__title {
    margin-bottom: 50px;
  }
}
.reach-statsbox {
  width: 100%;
  max-width: 896px;
  margin: 0 auto;
  margin-bottom: 120px;
  display: flex;
  gap: 0 50px;
  justify-content: space-between;
}
@media (max-width: 1700px) {
  .reach-statsbox {
    margin-bottom: 110px;
  }
}
@media (max-width: 1500px) {
  .reach-statsbox {
    margin-bottom: 90px;
  }
}
@media (max-width: 1024px) {
  .reach-statsbox {
    max-width: 600px;
    gap: 0 20px;
    margin-bottom: 70px;
  }
}
@media (max-width: 576px) {
  .reach-statsbox {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.reach-statscolumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(25.4% - 25px);
}
@media (max-width: 1024px) {
  .reach-statscolumn {
    width: calc(30% - 10px);
  }
}
@media (max-width: 576px) {
  .reach-statscolumn {
    width: 100%;
    justify-content: flex-start;
  }
}
.reach-statscolumn.reach-graphic {
  justify-content: center;
  width: calc(49.2% - 50px);
  padding: 20px 0;
}
@media (max-width: 1024px) {
  .reach-statscolumn.reach-graphic {
    width: calc(40% - 20px);
  }
}
@media (max-width: 576px) {
  .reach-statscolumn.reach-graphic {
    display: none;
  }
}
.reach-statscolumn img {
  width: 100%;
  object-fit: contain;
}
.reach-statscolumn:last-child .reach-stats__line {
  right: unset;
  left: -50px;
  background: linear-gradient(90deg, #6D7FF6 18.78%, #B75BFF 97.2%);
}
@media (max-width: 1024px) {
  .reach-statscolumn:last-child .reach-stats__line {
    left: -20px;
  }
}
.reach-statscolumn:last-child .reach-stats__line::after {
  left: unset;
  right: 0;
}
.reach-stats__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(50% - 15px);
  padding-bottom: 67px;
  z-index: 1;
}
@media (max-width: 1024px) {
  .reach-stats__item {
    height: calc(50% - 10px);
    padding-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .reach-stats__item {
    max-width: 200px;
    margin: 0 auto;
    height: auto;
    padding: 0 !important;
    margin-bottom: 40px;
  }
}
.reach-stats__item::before {
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  content: "";
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
  background: #6F7EF6;
}
@media (max-width: 1024px) {
  .reach-stats__item::before {
    bottom: 12px;
  }
}
@media (max-width: 576px) {
  .reach-stats__item::before {
    display: none;
  }
}
.reach-stats__item:last-child {
  padding-top: 67px;
  padding-bottom: 0;
}
@media (max-width: 1024px) {
  .reach-stats__item:last-child {
    padding-top: 50px;
  }
}
.reach-stats__item:last-child::before {
  bottom: unset;
  top: 22px;
}
@media (max-width: 1024px) {
  .reach-stats__item:last-child::before {
    top: 12px;
  }
}
.reach-stats__item:last-child .reach-stats__line {
  bottom: unset;
  top: 0;
}
.reach-stats__item:last-child .reach-stats__line::after {
  transform: translateY(0) rotate(0);
}
.reach-stats__number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 28px;
}
@media (max-width: 1024px) {
  .reach-stats__number {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 18px;
  }
}
@media (max-width: 576px) {
  .reach-stats__number {
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 40px;
  }
}
.reach-stats__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FAFAFA;
}
@media (max-width: 1024px) {
  .reach-stats__text {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 576px) {
  .reach-stats__text {
    font-size: 16px;
    line-height: 20px;
  }
}
.reach-stats__line {
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  position: absolute;
  width: calc(50% + 50px);
  right: -50px;
  bottom: 0;
  height: 1px;
}
@media (max-width: 1024px) {
  .reach-stats__line {
    width: calc(50% + 20px);
    right: -20px;
  }
}
@media (max-width: 576px) {
  .reach-stats__line {
    display: none;
  }
}
.reach-stats__line::after {
  height: 22px;
  width: 1px;
  background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%) rotate(180deg);
}
@media (max-width: 1024px) {
  .reach-stats__line::after {
    height: 12px;
  }
}
.reach-orderbox {
  width: 100%;
  max-width: 572px;
  margin: 0 auto;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  border-radius: 20px 20px 0px 0px;
  padding: 40px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1700px) {
  .reach-orderbox {
    padding: 40px;
  }
}
@media (max-width: 1500px) {
  .reach-orderbox {
    padding: 35px 30px;
  }
}
@media (max-width: 1024px) {
  .reach-orderbox {
    padding: 30px 20px;
    border-radius: 10px 10px 0px 0px;
    max-width: 600px;
  }
}
.reach-orderbox__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  margin-bottom: 32px;
}
@media (max-width: 1700px) {
  .reach-orderbox__title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 28px;
  }
}
@media (max-width: 1500px) {
  .reach-orderbox__title {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 1024px) {
  .reach-orderbox__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }
}

.technologies-section {
  padding-bottom: 244px;
}
@media (max-width: 1700px) {
  .technologies-section {
    padding-bottom: 200px;
  }
}
@media (max-width: 1500px) {
  .technologies-section {
    padding-bottom: 180px;
  }
}
@media (max-width: 1300px) {
  .technologies-section {
    padding-bottom: 160px;
  }
}
@media (max-width: 1024px) {
  .technologies-section {
    padding-bottom: 120px;
  }
}
@media (max-width: 576px) {
  .technologies-section {
    padding-bottom: 20px;
  }
}
.technologies-section__title {
  margin-bottom: 170px;
}
@media (max-width: 1700px) {
  .technologies-section__title {
    margin-bottom: 145px;
  }
}
@media (max-width: 1500px) {
  .technologies-section__title {
    margin-bottom: 135px;
  }
}
@media (max-width: 1300px) {
  .technologies-section__title {
    margin-bottom: 105px;
  }
}
@media (max-width: 1024px) {
  .technologies-section__title {
    margin-bottom: 75px;
  }
}
@media (max-width: 576px) {
  .technologies-section__title {
    margin-bottom: 50px;
  }
}
.technologies-box {
  width: 100%;
  position: relative;
  aspect-ratio: 1/1;
  max-width: 480px;
  z-index: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .technologies-box {
    max-width: 380px;
  }
}
@media (max-width: 576px) {
  .technologies-box {
    aspect-ratio: unset;
  }
}
.technologies__pulse-circle-1 {
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0.7;
  transform: translate(-50%, -50%);
}
.technologies__pulse-circle-1 .pulse-circle__large, .technologies__pulse-circle-1 .pulse-circle__medium, .technologies__pulse-circle-1 .pulse-circle__small {
  border-color: #8DADFC;
}
.technologies__pulse-circle-1 .pulse-circle__large, .technologies__pulse-circle-1 .pulse-circle__small {
  border-style: solid;
}
.technologies__pulse-circle-1 .pulse-circle__medium {
  border-style: dashed;
}
@media (max-width: 576px) {
  .technologies__pulse-circle-1 {
    display: none;
  }
}
.technologies__logo {
  width: 149px;
  position: relative;
}
.technologies__logo img {
  width: 100%;
  transform: translateY(4px);
  object-fit: contain;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .technologies__logo img {
    transform: translateY(3px);
  }
}
@media (max-width: 1024px) {
  .technologies__logo {
    width: 117px;
  }
}
@media (max-width: 576px) {
  .technologies__logo {
    display: none;
  }
}
@media (max-width: 576px) {
  .technologies-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
}
.technologies-item {
  width: 100%;
  max-width: 193px;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1024px) {
  .technologies-item {
    max-width: 160px;
  }
}
@media (max-width: 576px) {
  .technologies-item {
    position: relative;
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    right: unset !important;
    transform: none !important;
    width: calc(50% - 10px);
    margin-bottom: 30px;
    max-width: none;
  }
}
@media (max-width: 450px) {
  .technologies-item {
    width: 100%;
    margin-bottom: 40px;
  }
}
.technologies-item:nth-child(1) {
  top: -15px;
  left: -20px;
}
.technologies-item:nth-child(2) {
  top: -42px;
  right: -15px;
}
@media (max-width: 1024px) {
  .technologies-item:nth-child(2) {
    top: -17px;
    right: -15px;
  }
}
.technologies-item:nth-child(3) {
  top: 230px;
  left: -90px;
}
@media (max-width: 1024px) {
  .technologies-item:nth-child(3) {
    top: 200px;
    left: -70px;
  }
}
.technologies-item:nth-child(4) {
  top: 239px;
  right: -95px;
}
@media (max-width: 1024px) {
  .technologies-item:nth-child(4) {
    top: 200px;
    right: -75px;
  }
}
.technologies-item:nth-child(5) {
  bottom: -77px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1024px) {
  .technologies-item:nth-child(5) {
    bottom: -67px;
  }
}
.technologies-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 97px;
  margin-bottom: 15px;
}
.technologies-item__icon img {
  max-width: 100%;
  object-fit: contain;
}
@media (max-width: 576px) {
  .technologies-item__icon img {
    aspect-ratio: 1/1;
  }
}
@media (max-width: 1024px) {
  .technologies-item__icon {
    max-width: 60px;
    margin-bottom: 11px;
  }
}
@media (max-width: 576px) {
  .technologies-item__icon {
    max-width: 70px;
  }
}
@media (max-width: 450px) {
  .technologies-item__icon {
    max-width: 100px;
    margin-bottom: 13px;
  }
}
.technologies-item__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #252525;
}
.technologies-item__text b {
  font-weight: 600;
}
@media (max-width: 1024px) {
  .technologies-item__text {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 450px) {
  .technologies-item__text {
    font-size: 16px;
    max-width: 250px;
  }
}

.video-section {
  padding-top: 120px;
  padding-bottom: 120px;
  box-shadow: 2px -2px 71px 13px rgba(0, 0, 0, 0.18) inset;
}
@media (max-width: 1700px) {
  .video-section {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media (max-width: 1500px) {
  .video-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 1024px) {
  .video-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.video-section__box {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 1700px) {
  .video-section__box {
    max-width: 1300px;
  }
}
@media (max-width: 1500px) {
  .video-section__box {
    max-width: 1200px;
  }
}
.video-section__video {
  width: 100%;
  position: relative;
}
.video-section__video video {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1.77777778/1;
}

.cases-section {
  padding-bottom: 160px;
}
@media (max-width: 1700px) {
  .cases-section {
    padding-bottom: 130px;
  }
}
@media (max-width: 1500px) {
  .cases-section {
    padding-bottom: 100px;
  }
}
@media (max-width: 1300px) {
  .cases-section {
    padding-bottom: 80px;
  }
}
@media (max-width: 1024px) {
  .cases-section {
    padding-bottom: 50px;
  }
}
.cases-section__title {
  margin-bottom: 190px;
}
@media (max-width: 1700px) {
  .cases-section__title {
    margin-bottom: 150px;
  }
}
@media (max-width: 1500px) {
  .cases-section__title {
    margin-bottom: 120px;
  }
}
@media (max-width: 1300px) {
  .cases-section__title {
    margin-bottom: 90px;
  }
}
@media (max-width: 1024px) {
  .cases-section__title {
    margin-bottom: 40px;
  }
}
.cases-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cases-list {
  width: 100%;
  position: relative;
}
.cases-item {
  padding: 60px 0;
  margin-bottom: 100px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
@media (max-width: 1700px) {
  .cases-item {
    padding: 40px 0;
    margin-bottom: 80px;
  }
}
@media (max-width: 1500px) {
  .cases-item {
    padding: 30px 0;
    margin-bottom: 70px;
    gap: 18px;
  }
}
@media (max-width: 1024px) {
  .cases-item {
    padding: 15px 0;
    gap: 18px;
    flex-direction: column;
    margin-bottom: 60px;
    gap: 20px;
  }
}
@media (max-width: 576px) {
  .cases-item {
    margin-bottom: 50px;
  }
}
.cases-item__column {
  width: calc(33.33333333% - 16px);
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (max-width: 1500px) {
  .cases-item__column {
    width: calc(33.33333333% - 12px);
  }
}
@media (max-width: 1024px) {
  .cases-item__column {
    width: 100% !important;
  }
}
.cases-item__column:nth-child(1) {
  width: calc(38% - 16px);
}
@media (max-width: 1500px) {
  .cases-item__column:nth-child(1) {
    width: calc(38% - 12px);
  }
}
.cases-item__column:nth-child(1)::after {
  width: 304px;
  aspect-ratio: 1/1;
  position: absolute;
  background: rgba(220, 167, 12, 0.24);
  border-radius: 10px;
  content: "";
  z-index: -1;
  left: 25%;
  bottom: 15%;
}
@media (max-width: 1700px) {
  .cases-item__column:nth-child(1)::after {
    width: 290px;
  }
}
@media (max-width: 1500px) {
  .cases-item__column:nth-child(1)::after {
    width: 255px;
  }
}
@media (max-width: 1300px) {
  .cases-item__column:nth-child(1)::after {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item__column:nth-child(1)::after {
    display: none !important;
  }
}
.cases-item__column:nth-child(2) {
  width: calc(51.7% - 16px);
}
@media (max-width: 1500px) {
  .cases-item__column:nth-child(2) {
    width: calc(51.7% - 12px);
  }
}
.cases-item__column:nth-child(2)::after {
  width: 304px;
  aspect-ratio: 1/1;
  position: absolute;
  background: rgba(220, 167, 12, 0.24);
  border-radius: 10px;
  content: "";
  z-index: -1;
  left: -18.7%;
  top: 15.5%;
}
@media (max-width: 1700px) {
  .cases-item__column:nth-child(2)::after {
    width: 290px;
  }
}
@media (max-width: 1500px) {
  .cases-item__column:nth-child(2)::after {
    width: 255px;
  }
}
@media (max-width: 1300px) {
  .cases-item__column:nth-child(2)::after {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item__column:nth-child(2)::after {
    display: none !important;
  }
}
.cases-item__column:nth-child(2)::before {
  width: 304px;
  aspect-ratio: 1/1;
  position: absolute;
  background: rgba(220, 167, 12, 0.24);
  border-radius: 10px;
  content: "";
  z-index: -1;
  right: -9%;
  bottom: 26%;
}
@media (max-width: 1700px) {
  .cases-item__column:nth-child(2)::before {
    width: 290px;
  }
}
@media (max-width: 1500px) {
  .cases-item__column:nth-child(2)::before {
    width: 255px;
  }
}
@media (max-width: 1300px) {
  .cases-item__column:nth-child(2)::before {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item__column:nth-child(2)::before {
    display: none !important;
  }
}
.cases-item__column:nth-child(3) {
  width: calc(10.3% - 16px);
}
@media (max-width: 1500px) {
  .cases-item__column:nth-child(3) {
    width: calc(10.3% - 12px);
  }
}
.cases-item__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 4px;
}
@media (max-width: 1700px) {
  .cases-item__name {
    font-size: 32px;
    line-height: 38px;
  }
}
@media (max-width: 1500px) {
  .cases-item__name {
    font-size: 28px;
    line-height: 34px;
  }
}
@media (max-width: 1300px) {
  .cases-item__name {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 1024px) {
  .cases-item__name {
    font-size: 22px;
    line-height: 28px;
  }
}
@media (max-width: 576px) {
  .cases-item__name {
    font-size: 20px;
    line-height: 24px;
  }
}
.cases-item__description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #8A8A8A;
  margin-bottom: 48px;
}
@media (max-width: 1700px) {
  .cases-item__description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1300px) {
  .cases-item__description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .cases-item__description {
    margin-bottom: 20px;
  }
}
.cases-item__content {
  width: 100%;
  position: relative;
  gap: 24px;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media (max-width: 1500px) {
  .cases-item__content {
    gap: 18px;
  }
}
@media (max-width: 1024px) {
  .cases-item__content {
    gap: 20px;
  }
}
.cases-item__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #252525;
  margin-bottom: 20px;
}
@media (max-width: 1700px) {
  .cases-item__title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 18px;
  }
}
@media (max-width: 1500px) {
  .cases-item__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
@media (max-width: 1024px) {
  .cases-item__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
@media (max-width: 576px) {
  .cases-item__title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 14px;
  }
}
.cases-item__textrow {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 24px;
}
@media (max-width: 1500px) {
  .cases-item__textrow {
    width: calc(100% + 18px);
    margin-left: -9px;
    margin-right: -9px;
    grid-row-gap: 18px;
  }
}
.cases-item__textrow.double__row .cases-item__text-wrap {
  width: 50%;
}
@media (max-width: 1200px) {
  .cases-item__textrow.double__row .cases-item__text-wrap {
    width: 100%;
  }
}
.cases-item__textrow.tripple__row .cases-item__text-wrap {
  width: 33.3333333333%;
}
@media (max-width: 1300px) {
  .cases-item__textrow.tripple__row .cases-item__text-wrap {
    width: 50%;
  }
  .cases-item__textrow.tripple__row .cases-item__text-wrap:nth-child(3) {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .cases-item__textrow.tripple__row .cases-item__text-wrap {
    width: 100%;
  }
}
.cases-item__text-wrap {
  width: 100%;
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1500px) {
  .cases-item__text-wrap {
    padding: 0 9px;
  }
}
.cases-item__text {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  border: 1px solid #8A8A8A;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #252525;
  background-color: #fff;
}
@media (max-width: 1700px) {
  .cases-item__text {
    font-size: 15px;
    line-height: 21px;
  }
}
@media (max-width: 1500px) {
  .cases-item__text {
    padding: 18px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .cases-item__text {
    padding: 16px !important;
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 576px) {
  .cases-item__text {
    font-size: 14px;
    line-height: 18px;
  }
}
.cases-item__text.less-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (max-width: 1500px) {
  .cases-item__text.less-padding {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.cases-item__text.more-padding {
  padding-top: 22px;
  padding-bottom: 22px;
}
@media (max-width: 1500px) {
  .cases-item__text.more-padding {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
.cases-item__text p {
  margin-bottom: 15px;
}
@media (max-width: 1500px) {
  .cases-item__text p {
    margin-bottom: 12px;
  }
}
.cases-item__text p:last-child {
  margin-bottom: 0;
}
.cases-item__text li {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 15px;
  position: relative;
}
@media (max-width: 1500px) {
  .cases-item__text li {
    margin-bottom: 12px;
  }
}
.cases-item__text li::before {
  width: 3px;
  min-width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #252525;
  content: "";
  position: absolute;
  left: -2px;
  top: 11px;
}
@media (max-width: 1700px) {
  .cases-item__text li::before {
    top: 10px;
  }
}
@media (max-width: 1500px) {
  .cases-item__text li::before {
    top: 9px;
  }
}
.cases-item__text li:last-child {
  margin-bottom: 0;
}
.cases-item__photo {
  width: 100%;
  position: relative;
}
.cases-item__photo img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .cases-item__photo {
    max-width: 800px;
    margin: 0 auto;
  }
}
.cases-item__td.cases-item-padding-right {
  padding-right: 130px;
}
@media (max-width: 1400px) {
  .cases-item__td.cases-item-padding-right {
    padding-right: 88px;
  }
}
@media (max-width: 1200px) {
  .cases-item__td.cases-item-padding-right {
    padding-right: 0;
  }
}
.cases-item__photorow {
  width: 100%;
  position: relative;
  display: flex;
  gap: 24px;
}
@media (max-width: 1500px) {
  .cases-item__photorow {
    gap: 18px;
  }
}
@media (max-width: 1200px) {
  .cases-item__photorow {
    flex-direction: column;
  }
}
.cases-item__photorow .cases-item__photo {
  width: calc(65.9% - 12px + 123px);
  margin-left: -123px;
}
@media (max-width: 1500px) {
  .cases-item__photorow .cases-item__photo {
    width: calc(65.9% - 12px + 129px);
    margin-left: -129px;
  }
}
@media (max-width: 1400px) {
  .cases-item__photorow .cases-item__photo {
    width: calc(65.9% - 12px + 87px);
    margin-left: -87px;
  }
}
@media (max-width: 1200px) {
  .cases-item__photorow .cases-item__photo {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  .cases-item__photorow .cases-item__photo {
    margin: 0 auto;
  }
}
@media (max-width: 1400px) {
  .cases-item__photorow .cases-item__photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.cases-item__photorow .cases-item__text-wrap {
  width: calc(34.1% - 12px);
  padding: 0;
}
@media (max-width: 1200px) {
  .cases-item__photorow .cases-item__text-wrap {
    width: 100%;
  }
}
.cases-item__photorow .cases-item__text {
  justify-content: center;
}
.cases-item__smartphones {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .cases-item__smartphones {
    flex-direction: row;
    gap: 20px;
  }
  .cases-item__smartphones .cases-item__photo {
    width: calc(33.3333333333% - 13.3333333333px);
  }
}
.cases-item__logorow {
  width: 100%;
  position: relative;
  display: flex;
  gap: 14px;
  justify-content: space-between;
  align-items: flex-end;
}
.cases-item__logorow .cases-item__sidebar {
  width: calc(72% - 7px);
}
@media (max-width: 1024px) {
  .cases-item__logorow .cases-item__sidebar {
    width: 100%;
  }
}
.cases-item__logorow .cases-item__photo {
  position: absolute;
  right: 0;
  width: calc(28% - 7px);
  bottom: -40%;
}
@media (max-width: 1500px) {
  .cases-item__logorow .cases-item__photo {
    bottom: -35%;
  }
}
@media (max-width: 1300px) {
  .cases-item__logorow .cases-item__photo {
    bottom: -29%;
  }
}
@media (max-width: 1200px) {
  .cases-item__logorow .cases-item__photo {
    bottom: -25%;
  }
}
@media (max-width: 1024px) {
  .cases-item__logorow .cases-item__photo {
    display: none;
  }
}
.cases-item__row-photos {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}
@media (max-width: 1500px) {
  .cases-item__row-photos {
    gap: 18px;
  }
}
@media (max-width: 1024px) {
  .cases-item__row-photos {
    gap: 20px;
    flex-direction: column;
  }
}
.cases-item__row-photos .cases-item__photo {
  width: calc(50% - 12px);
}
@media (max-width: 1500px) {
  .cases-item__row-photos .cases-item__photo {
    width: calc(50% - 9px);
  }
}
@media (max-width: 1024px) {
  .cases-item__row-photos .cases-item__photo {
    width: 100% !important;
  }
  .cases-item__row-photos .cases-item__photo:nth-child(1) {
    display: none;
  }
}
.cases-item.cases-item__donella .cases-item__column {
  width: calc(33.33333333% - 16px);
}
@media (max-width: 1500px) {
  .cases-item.cases-item__donella .cases-item__column {
    width: calc(33.33333333% - 12px);
  }
}
.cases-item.cases-item__donella .cases-item__column:nth-child(1)::after {
  background: rgba(227, 200, 180, 0.22);
  width: 120px;
  aspect-ratio: 1/2.66942149;
  left: 0;
  top: 18%;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(1)::after {
    width: 110px;
    top: 16%;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(1)::after {
    width: 90px;
    top: 17%;
  }
}
.cases-item.cases-item__donella .cases-item__column:nth-child(1)::before {
  width: 320px;
  aspect-ratio: 1/1;
  position: absolute;
  background: rgba(227, 200, 180, 0.22);
  border-radius: 10px;
  content: "";
  z-index: -1;
  right: -25.7%;
  bottom: 6%;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(1)::before {
    width: 290px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(1)::before {
    width: 255px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(1)::before {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(1)::before {
    display: none !important;
  }
}
.cases-item.cases-item__donella .cases-item__column:nth-child(2)::after, .cases-item.cases-item__donella .cases-item__column:nth-child(2)::before {
  display: none;
}
@media (max-width: 1300px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(2) .cases-item__td:nth-child(1) .cases-item__photo {
    margin-top: 35%;
  }
}
@media (max-width: 1150px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(2) .cases-item__td:nth-child(1) .cases-item__photo {
    display: none;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(2) .cases-item__td:nth-child(1) .cases-item__photo {
    display: block;
    margin-top: 0;
  }
}
@media (max-width: 576px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(2) .cases-item__td:nth-child(1) .cases-item__photo {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
}
@media (min-width: 1025px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3) .cases-item__content {
    gap: 0;
  }
}
@media (min-width: 1025px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3) .cases-item__photo img {
    max-height: 260px;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3) .cases-item__td:nth-child(2) {
    display: none;
  }
}
@media (max-width: 1150px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3) .cases-item__td:nth-child(1) {
    width: calc(200% + 16px);
    margin-left: -105%;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3) .cases-item__td:nth-child(1) {
    width: 100%;
    margin: 0;
  }
}
.cases-item.cases-item__donella .cases-item__column:nth-child(3)::before {
  width: 320px;
  aspect-ratio: 1/1;
  position: absolute;
  background: rgba(227, 200, 180, 0.22);
  border-radius: 10px;
  content: "";
  z-index: -1;
  right: 0;
  top: 0;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::before {
    width: 290px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::before {
    width: 255px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::before {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::before {
    display: none !important;
  }
}
.cases-item.cases-item__donella .cases-item__column:nth-child(3)::after {
  width: 320px;
  aspect-ratio: 1/1;
  position: absolute;
  background: rgba(227, 200, 180, 0.22);
  border-radius: 10px;
  content: "";
  z-index: -1;
  left: -29%;
  bottom: 32%;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::after {
    width: 290px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::after {
    width: 255px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::after {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__donella .cases-item__column:nth-child(3)::after {
    display: none !important;
  }
}
@media (min-width: 1501px) {
  .cases-item.cases-item__donella .cases-item__description {
    width: calc(100% + 70px);
  }
}
@media (min-width: 1201px) {
  .cases-item.cases-item__donella .cases-item__textrow.double__row .cases-item__text {
    min-height: 136px;
    justify-content: center;
  }
}
.cases-item.cases-item__diamond .cases-item__column {
  width: calc(50% - 16px);
}
@media (max-width: 1500px) {
  .cases-item.cases-item__diamond .cases-item__column {
    width: calc(50% - 12px);
  }
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(1) .cases-item__row-photos {
  padding-left: 20px;
}
@media (max-width: 1024px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(1) .cases-item__row-photos {
    padding-left: 0;
  }
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(1) .cases-item__row-photos .cases-item__photo:nth-child(1) {
  width: calc(37.5% - 12px);
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(1) .cases-item__row-photos .cases-item__photo:nth-child(2) {
  width: calc(62.5% - 12px);
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(1)::after {
  width: 514px;
  background: rgba(157, 206, 206, 0.28);
  left: 0;
  bottom: 12%;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(1)::after {
    width: 450px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(1)::after {
    width: 400px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(1)::after {
    width: 340px;
  }
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(2) .cases-item__row-photos {
  padding-right: 20px;
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(2) .cases-item__row-photos .cases-item__photo:nth-child(1) {
  width: calc(43.7% - 12px);
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(2) .cases-item__row-photos .cases-item__photo:nth-child(2) {
  width: calc(56.3% - 12px);
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(2)::after {
  width: 514px;
  background: rgba(157, 206, 206, 0.28);
  left: unset;
  top: unset;
  right: 9%;
  bottom: 7%;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(2)::after {
    width: 450px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(2)::after {
    width: 400px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__diamond .cases-item__column:nth-child(2)::after {
    width: 340px;
  }
}
.cases-item.cases-item__diamond .cases-item__column:nth-child(2)::before {
  display: none;
}
.cases-item.cases-item__diamond .cases-item__text {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (min-width: 1201px) {
  .cases-item.cases-item__diamond .cases-item__textrow.tripple__row .cases-item__text {
    min-height: 136px;
    justify-content: center;
  }
}
.cases-item.cases-item__keep .cases-item__column {
  width: 10%;
}
.cases-item.cases-item__keep .cases-item__column:nth-child(1) {
  width: calc(34.3% - 16px);
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(1) {
    width: calc(34.3% - 12px);
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(1)::after {
  width: 390px;
  background: rgba(2, 108, 94, 0.32);
  left: 0;
  bottom: 0;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(1)::after {
    width: 330px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(1)::after {
    width: 280px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(1)::after {
    width: 220px;
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(1) .cases-item__photo {
  width: 87.5%;
  margin-left: auto;
}
@media (min-width: 1025px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(1) .cases-item__content {
    justify-content: flex-start;
  }
}
@media (min-width: 1201px) and (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(1) .cases-item__td:nth-child(2) .cases-item__text-wrap {
    width: 50%;
  }
  .cases-item.cases-item__keep .cases-item__column:nth-child(1) .cases-item__td:nth-child(2) .cases-item__text-wrap:nth-child(3) {
    width: 100%;
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(2) {
  z-index: 1;
  width: calc(33% - 16px);
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(2) {
    width: calc(33% - 12px);
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(2)::after {
  display: none;
}
.cases-item.cases-item__keep .cases-item__column:nth-child(2) .cases-item__photo {
  width: 155%;
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(2) .cases-item__photo {
    width: 139%;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(2) .cases-item__photo {
    width: 100%;
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(2)::before {
  display: none;
}
.cases-item.cases-item__keep .cases-item__column:nth-child(3) {
  width: calc(32.7% - 16px);
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3) {
    width: calc(32.7% - 12px);
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(3)::after {
  width: 390px;
  background: #F0F0F0;
  right: 4%;
  top: 4%;
  content: "";
  position: absolute;
  aspect-ratio: 1/1;
  border-radius: 10px;
  z-index: -1;
}
@media (max-width: 1700px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3)::after {
    width: 330px;
  }
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3)::after {
    width: 280px;
  }
}
@media (max-width: 1300px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3)::after {
    width: 220px;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3)::after {
    display: none;
  }
}
.cases-item.cases-item__keep .cases-item__column:nth-child(3) .cases-item__td:nth-child(1) {
  padding-left: 55.5%;
}
@media (max-width: 1500px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3) .cases-item__td:nth-child(1) {
    padding-left: 40%;
  }
}
@media (max-width: 1024px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3) .cases-item__td:nth-child(1) {
    padding-left: 0;
  }
}
@media (min-width: 1025px) {
  .cases-item.cases-item__keep .cases-item__column:nth-child(3) .cases-item__content {
    justify-content: flex-end;
  }
}
@media (min-width: 1201px) {
  .cases-item.cases-item__keep .cases-item__textrow.tripple__row .cases-item__text {
    min-height: 112px;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: center;
  }
}

.reviews-section {
  padding-bottom: 140px;
}
@media (max-width: 1700px) {
  .reviews-section {
    padding-bottom: 110px;
  }
}
@media (max-width: 1500px) {
  .reviews-section {
    padding-bottom: 80px;
  }
}
@media (max-width: 1024px) {
  .reviews-section {
    padding-bottom: 50px;
  }
}
.reviews-section__title {
  margin-bottom: 170px;
}
@media (max-width: 1700px) {
  .reviews-section__title {
    margin-bottom: 150px;
  }
}
@media (max-width: 1500px) {
  .reviews-section__title {
    margin-bottom: 125px;
  }
}
@media (max-width: 1300px) {
  .reviews-section__title {
    margin-bottom: 95px;
  }
}
@media (max-width: 1024px) {
  .reviews-section__title {
    margin-bottom: 50px;
  }
}
.reviews-box {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.reviews-column {
  width: 100%;
  position: relative;
  margin-bottom: 78px;
}
@media (max-width: 1700px) {
  .reviews-column {
    margin-bottom: 70px;
  }
}
@media (max-width: 1500px) {
  .reviews-column {
    margin-bottom: 60px;
  }
}
@media (max-width: 1024px) {
  .reviews-column {
    margin-bottom: 40px;
  }
}
.reviews-column:last-child {
  margin-bottom: 0;
}
.reviews-column__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 58px;
}
@media (max-width: 1700px) {
  .reviews-column__title {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1500px) {
  .reviews-column__title {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .reviews-column__title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .reviews-column__title {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1101px) {
  .reviews-column__title.clutch-reviews__title {
    text-align: center;
  }
}
.reviews-clutch {
  width: 100%;
  position: relative;
}
.reviews-clutch.active .reviews-clutch-content::after {
  opacity: 0;
  visibility: hidden;
}
.reviews-clutch.active .reviews-clutch-box {
  aspect-ratio: unset;
}
.reviews-clutch-content {
  width: 100%;
  max-width: 1020px;
  position: relative;
  margin: 0 auto;
}
.reviews-clutch-content::after {
  width: 100%;
  position: absolute;
  z-index: 1;
  transition: 0.3s;
  bottom: 0;
  left: 0;
  height: 166px;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #343434 100%);
  content: "";
}
.reviews-clutch-box {
  width: 100%;
  position: relative;
  aspect-ratio: 1.29739777/1;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .reviews-clutch-box {
    aspect-ratio: 1/1;
  }
}
.reviews-clutch-box img {
  width: 100%;
  pointer-events: none;
  object-fit: contain;
}
.reviews-clutch-more__btn {
  width: 60px;
  position: relative;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
@media (max-width: 1500px) {
  .reviews-clutch-more__btn {
    width: 50px;
    margin-top: 7px;
  }
}
@media (max-width: 1024px) {
  .reviews-clutch-more__btn {
    width: 40px;
    margin-top: 5px;
  }
}
@media (max-width: 576px) {
  .reviews-clutch-more__btn {
    width: 35px;
  }
}
.reviews-clutch-more__btn img {
  width: 100%;
  object-fit: contain;
  transition: 0.3s;
}
@media (min-width: 1025px) {
  .reviews-clutch-more__btn:hover {
    transform: translateY(5px);
  }
}
.reviews-clutch-more__btn.active {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.reviews-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.reviews-card {
  width: calc(50% - 10px);
  position: relative;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1150px) {
  .reviews-card {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .reviews-card {
    flex-direction: column;
  }
}
.reviews-card__content {
  width: 65%;
  padding: 24px;
  border-right: 1px solid #E4EFF3;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .reviews-card__content {
    padding: 20px;
    width: 60%;
  }
}
@media (max-width: 576px) {
  .reviews-card__content {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #E4EFF3;
  }
}
.reviews-card__sidebar {
  padding: 24px;
  width: 35%;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .reviews-card__sidebar {
    padding: 20px;
    width: 40%;
  }
}
@media (max-width: 576px) {
  .reviews-card__sidebar {
    width: 100%;
  }
}
.reviews-card__header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 24px;
}
@media (max-width: 1500px) {
  .reviews-card__header {
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__header {
    margin-bottom: 15px;
  }
}
.reviews-card__title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #6B7A7E;
}
@media (max-width: 1500px) {
  .reviews-card__title {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__title {
    font-size: 14px;
    line-height: 24px;
  }
}
.reviews-card__rating {
  display: inline-flex;
  align-items: center;
}
.reviews-card__rating-value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #252525;
  margin-right: 11px;
}
@media (max-width: 1500px) {
  .reviews-card__rating-value {
    font-size: 22px;
    line-height: 30px;
    margin-right: 8px;
  }
}
@media (max-width: 1500px) {
  .reviews-card__rating-value {
    font-size: 18px;
    line-height: 24px;
    margin-right: 8px;
  }
}
.reviews-card__rating-stars {
  display: inline-flex;
  align-items: center;
  margin-top: -1px;
}
.reviews-card__star {
  width: 20px;
  min-width: 20px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1500px) {
  .reviews-card__star {
    width: 18px;
    min-width: 18px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__star {
    width: 16px;
    min-width: 16px;
  }
}
.reviews-card__star img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
.reviews-card__date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #6B7A7E;
  margin-top: 24px;
}
@media (max-width: 1500px) {
  .reviews-card__date {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__date {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }
}
.reviews-card__feedback {
  width: 100%;
  position: relative;
}
.reviews-card__feedback-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #252525;
  margin-bottom: 12px;
}
@media (max-width: 1500px) {
  .reviews-card__feedback-title {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__feedback-title {
    font-size: 16px;
    line-height: 24px;
  }
}
.reviews-card__feedback-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #6B7A7E;
}
@media (max-width: 1500px) {
  .reviews-card__feedback-text {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__feedback-text {
    font-size: 14px;
    line-height: 18px;
  }
}
.reviews-card__profile {
  padding-bottom: 24px;
  position: relative;
  width: 100%;
}
@media (max-width: 1500px) {
  .reviews-card__profile {
    padding-bottom: 20px;
  }
}
.reviews-card__profile::after {
  content: "";
  background: #E4EFF3;
  position: absolute;
  left: -24px;
  bottom: 0;
  height: 1px;
  width: 196px;
}
@media (max-width: 1500px) {
  .reviews-card__profile::after {
    left: -20px;
  }
}
@media (max-width: 1150px) {
  .reviews-card__profile::after {
    width: calc(100% + 40px);
  }
}
.reviews-card__job {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #252525;
  margin-bottom: 24px;
}
@media (max-width: 1500px) {
  .reviews-card__job {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__job {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
  }
}
.reviews-card__user {
  display: flex;
  align-items: center;
}
.reviews-card__usericon {
  width: 76px;
  min-width: 76px;
  aspect-ratio: 1/1;
  margin-right: 12px;
}
@media (max-width: 1500px) {
  .reviews-card__usericon {
    width: 60px;
    min-width: 60px;
    margin-right: 10px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__usericon {
    width: 45px;
    min-width: 45px;
  }
}
.reviews-card__usericon img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}
.reviews-card__username {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #6B7A7E;
}
@media (max-width: 1500px) {
  .reviews-card__username {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__username {
    font-size: 14px;
    line-height: 18px;
  }
}
.reviews-card__categories {
  width: 100%;
  padding-top: 24px;
}
@media (max-width: 1500px) {
  .reviews-card__categories {
    padding-top: 20px;
  }
}
.reviews-card__categorie__item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 3px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #6B7A7E;
}
@media (max-width: 1500px) {
  .reviews-card__categorie__item {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__categorie__item {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}
.reviews-card__categorie__item:last-child {
  margin-bottom: 0;
}
.reviews-card__categorie__item .symbol {
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}
.reviews-card__categorie__item .symbol .icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  fill: #BC65FF;
}
.reviews-card__categorie__item .symbol .icon.stroke {
  fill: transparent;
  stroke: #BC65FF;
  stroke-width: 1.2px;
}
@media (max-width: 1500px) {
  .reviews-card__categorie__item .symbol {
    margin-top: 2px;
  }
}
@media (max-width: 1024px) {
  .reviews-card__categorie__item .symbol {
    margin-top: 0;
    margin-right: 7px;
  }
}

.contacts-section {
  padding-top: 96px;
  padding-bottom: 190px;
  overflow: hidden;
}
@media (max-width: 1700px) {
  .contacts-section {
    padding-bottom: 170px;
    padding-top: 80px;
  }
}
@media (max-width: 1500px) {
  .contacts-section {
    padding-bottom: 140px;
    padding-top: 70px;
  }
}
@media (max-width: 1300px) {
  .contacts-section {
    padding-bottom: 120px;
  }
}
@media (max-width: 1024px) {
  .contacts-section {
    padding-bottom: 60px;
    padding-top: 40px;
  }
}
.contacts-section__title {
  margin-bottom: 155px;
}
@media (max-width: 1700px) {
  .contacts-section__title {
    margin-bottom: 140px;
  }
}
@media (max-width: 1500px) {
  .contacts-section__title {
    margin-bottom: 110px;
  }
}
@media (max-width: 1300px) {
  .contacts-section__title {
    margin-bottom: 100px;
  }
}
@media (max-width: 1024px) {
  .contacts-section__title {
    margin-bottom: 55px;
  }
}
.contacts-section__wrapp {
  width: 100%;
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
}
.contacts-section__form {
  width: 100%;
  position: relative;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  box-shadow: 0px 19px 40px rgba(100, 100, 100, 0.23);
  border-radius: 20px;
  padding: 80px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1700px) {
  .contacts-section__form {
    padding: 70px;
  }
}
@media (max-width: 1500px) {
  .contacts-section__form {
    padding: 55px;
  }
}
@media (max-width: 1300px) {
  .contacts-section__form {
    padding: 50px 40px;
  }
}
@media (max-width: 1024px) {
  .contacts-section__form {
    padding: 30px 20px;
    border-radius: 10px;
  }
}
.contacts-section__form::after {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  content: "";
  position: absolute;
  background: #fff;
  border-radius: 19px;
  top: 1px;
  left: 1px;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .contacts-section__form::after {
    border-radius: 9px;
  }
}
.contacts-section__form-thanks {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #fff;
  border-radius: 19px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.contacts-section__form-thanks .contacts-section__subtitle {
  line-height: 32px;
  margin-bottom: 0;
}
@media (max-width: 1700px) {
  .contacts-section__form-thanks .contacts-section__subtitle {
    line-height: 29px;
  }
}
@media (max-width: 1500px) {
  .contacts-section__form-thanks .contacts-section__subtitle {
    line-height: 27px;
  }
}
@media (max-width: 1024px) {
  .contacts-section__form-thanks .contacts-section__subtitle {
    line-height: 25px;
  }
}
.contacts-section__form-thanks.active {
  opacity: 1;
  visibility: visible;
}
.contacts-section__subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 60px;
}
@media (max-width: 1700px) {
  .contacts-section__subtitle {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1500px) {
  .contacts-section__subtitle {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .contacts-section__subtitle {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}
.contacts-section__btn {
  min-width: 335px;
}
@media (max-width: 450px) {
  .contacts-section__btn {
    min-width: 100%;
  }
}
.contacts-section__pulse-circle-1 {
  width: 430px;
  top: -40%;
  left: -13%;
}
@media (max-width: 1700px) {
  .contacts-section__pulse-circle-1 {
    width: 400px;
    top: -45%;
  }
}
@media (max-width: 1500px) {
  .contacts-section__pulse-circle-1 {
    width: 350px;
    left: -11%;
  }
}
@media (max-width: 1200px) {
  .contacts-section__pulse-circle-1 {
    width: 300px;
    top: -40%;
  }
}
@media (max-width: 1024px) {
  .contacts-section__pulse-circle-1 {
    top: -27%;
    left: -12%;
  }
}
@media (max-width: 576px) {
  .contacts-section__pulse-circle-1 {
    width: 200px;
    top: -20%;
    left: -25%;
  }
}
.contacts-section__pulse-circle-2 {
  width: 430px;
  bottom: -40%;
  right: -13%;
}
@media (max-width: 1700px) {
  .contacts-section__pulse-circle-2 {
    width: 400px;
    bottom: -45%;
  }
}
@media (max-width: 1500px) {
  .contacts-section__pulse-circle-2 {
    width: 350px;
    right: -11%;
  }
}
@media (max-width: 1200px) {
  .contacts-section__pulse-circle-2 {
    width: 300px;
    bottom: -40%;
  }
}
@media (max-width: 1024px) {
  .contacts-section__pulse-circle-2 {
    bottom: -27%;
    right: -12%;
  }
}
@media (max-width: 576px) {
  .contacts-section__pulse-circle-2 {
    width: 200px;
    bottom: -20%;
    right: -25%;
  }
}