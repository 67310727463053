.levels{
  &-section{
    padding-bottom: px(270);
    @media(max-width:1700px){
      padding-bottom: px(220);
    }
    @media(max-width:1500px){
      padding-bottom: px(180);
    }
    @media(max-width:1400px){
      padding-bottom: px(150);
    }
    @media(max-width:1250px){
      padding-bottom: px(130);
    }
    @media(max-width:1024px){
      padding-bottom: px(60);
    }
    @media(max-width:576px){
      padding-bottom: px(50);
    }
  } 

  &-section__title{
    margin-bottom: px(155);
    @media(max-width:1700px){
      margin-bottom: px(135);
    }
    @media(max-width:1500px){
      margin-bottom: px(115);
    }
    @media(max-width:1350px){
      margin-bottom: px(100);
    }
    @media(max-width:1024px){
      margin-bottom: px(45);
    }
    @media(max-width:576px){
      margin-bottom: px(40);
    }
  }

  &-roadmap {
    width: 100%;
    max-width: px(1550);
    position: relative;
    margin: 0 auto;
    z-index: 1;
    padding-right: px(144);
    padding-left: px(72);
    @media(max-width:1500px){
      padding-right: px(124);
      padding-left: px(52);
    }
    @media(max-width:1250px){
      padding-right: px(94);
      padding-left: px(42);
    }
    @media(max-width:1024px){
      padding: 0;
      display: flex;
      flex-direction: column;
      padding-bottom: px(80);
      padding-top: px(20);
    }
    @media(max-width:576px){
      padding-bottom: px(60);
    }
  }

  &-roadmap__line {
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    height: px(1);
    width: calc(100% - 72px);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    @media(max-width:1500px){
      width: calc(100% - 52px);
    }
    @media(max-width:1250px){
      width: calc(100% - 42px);
    }
    @media(max-width:1024px){
      width: 2px;
      height: calc(100% - 60px);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
    }
    @media(max-width:576px){
      left: px(30);
      height: calc(100% - 40px);
    }
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: px(12);
      height: px(12);
      border-radius: 50%;
      background: #AF60FF;
      @media(max-width:1500px){
        width: px(10);
        height: px(10);
      }
      @media(max-width:1024px){
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: px(12);
      height: px(12);
      border-radius: 50%;
      background: #6C96FF;
      @media(max-width:1500px){
        width: px(10);
        height: px(10);
      }
      @media(max-width:1024px){
        top: unset;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &-roadmap__list {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    @media(max-width:1350px){
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
    }
    @media(max-width:1250px){
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
    @media(max-width:1024px){
      flex-direction: column;
      width: 100%;
      margin: 0;
    }
  }

  &-roadmap__item {
    padding: px(180) px(40);
    position: relative;
    width: calc(100% / 7);
    @media(max-width:1700px){
      padding: px(165) px(40);
    }
    @media(max-width:1500px){
      padding: px(140) px(40);
    }
    @media(max-width:1350px){
      padding: px(125) px(30);
    }
    @media(max-width:1350px){
      padding: px(125) px(30);
    }
    @media(max-width:1250px){
      padding: px(125) px(20);
    }
    @media(max-width:1024px){
      padding: px(20) 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    @media(max-width:576px){
      justify-content: flex-start;
      padding: px(16) 0;
    }
    &:nth-child(2n){
      .levels-roadmap__info{
        top: unset;
        bottom: 0;
        @media(max-width:1024px){
          bottom: unset;
          top: 50%;
          transform: translateY(-50%);
          left: unset;
          right: 0;
          text-align: left;
        }
      }
      .levels-roadmap__step{
        &::before{
          top: unset;
          bottom: 0;
          transform: translateX(-50%) translateY(100%) rotate(180deg);
          @media(max-width:1024px){
            bottom: unset;
            top: 50%;
            left: unset;
            right: 0;
            transform: translateX(100%) translateY(-50%) rotate(180deg);
          }
        }
        &::after{
          top: unset;
          bottom: 0;
          transform: translateX(-50%) translateY(90px);
          @media(max-width:1500px){
            transform: translateX(-50%) translateY(65px);
          }
          @media(max-width:1250px){
            transform: translateX(-50%) translateY(50px);
          }
          @media(max-width:1024px){
            bottom: unset;
            top: 50%;
            left: unset;
            right: 0;
            transform: translateX(40px) translateY(-50%);
          }
          @media(max-width:576px){
            transform: translateX(30px) translateY(-50%);
          }
        }
      }
    }
  }

  &-roadmap__step{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: linear-gradient(180deg,  #84ADFF 0%, #C175FD 100%);
    box-shadow: 0px 9px 25px rgba(96, 95, 95, 0.06);
    padding: px(10);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(60);
    line-height: px(73);
    color: #FAFAFA;
    position: relative;
    z-index: 1;
    @media(max-width:1700px){
      font-size: px(54);
      line-height: px(60);
    }
    @media(max-width:1500px){
      font-size: px(50);
      line-height: px(56);
    }
    @media(max-width:1250px){
      font-size: px(44);
      line-height: px(50);
    }
    @media(max-width:1024px){
      font-size: px(36);
      line-height: px(40);
      max-width: px(100);
    }
    @media(max-width:700px){
      font-size: px(30);
      line-height: px(36);
      max-width: px(80);
    }
    @media(max-width:576px){
      font-size: px(24);
      line-height: px(30);
      max-width: px(60);
    }
    &::before{
      height: px(90);
      width: 2px;
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%) translateY(-100%);
      background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
      z-index: -1;
      @media(max-width:1500px){
        height: px(65);
      }
      @media(max-width:1250px){
        height: px(50);
      }
      @media(max-width:1024px){
        width: px(40);
        height: 2px;
        top: 50%;
        left: 0;
        transform: translateX(-100%) translateY(-50%);
      }
      @media(max-width:576px){
        width: px(30);
        left: unset;
        right: 0;
        transform: translateX(100%) translateY(-50%) rotate(180deg);
      }
    }
    &::after{
      width: px(12);
      height: px(12);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-90px);
      border-radius: 50%;
      background: #A767FF;
      content: '';
      z-index: -1;
      @media(max-width:1500px){
        width: px(10);
        height: px(10);
        transform: translateX(-50%) translateY(-65px);
      }
      @media(max-width:1250px){
        transform: translateX(-50%) translateY(-50px);
      }
      @media(max-width:1024px){
        top: 50%;
        left: 0;
        transform: translateX(-40px) translateY(-50%);
      }
      @media(max-width:576px){
        left: unset;
        right: 0;
        transform: translateX(30px) translateY(-50%);
      }
    }
  }

  &-roadmap__info{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% * 2);
    padding-left: 47%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: px(20);
    line-height: px(30);
    color: $baseBlack;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media(max-width:1700px){
      font-size: px(18);
      line-height: px(26);
    }
    @media(max-width:1350px){
      font-size: px(16);
      line-height: px(20);
    }
    @media(max-width:1250px){
      -webkit-line-clamp: 3;
    }
    @media(max-width:1150px){
     font-size: px(14);
     line-height: px(18);
    }
    @media(max-width:1024px){
      font-size: px(16);
      line-height: px(20);
      padding-left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% / 2 - 110px);
      text-align: right;
      -webkit-line-clamp: 4;
    }
    @media(max-width:700px){
      width: calc(100% / 2 - 95px);
    }
    @media(max-width:576px){
      width: calc(100% - 100px);
      left: unset;
      right: 0;
      text-align: left;
    }
  }

  &-roadmap__finish {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: px(44);
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
    @media(max-width:1500px){
      width: px(38);
    }
    @media(max-width:1250px){
      width: px(26);
    }
    @media(max-width:1024px){
      top: unset;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      width: px(36);
    }
    @media(max-width:576px){
      right: unset;
      left: 15px;
      transform: translateX(0);
      width: px(30);
    }
  }
}