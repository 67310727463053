.header {
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  background-color: $baseWhite;
  padding: px(10) px(30);
  box-shadow: 0px 9px 25px rgba(96, 95, 95, 0.06);
  min-height: px(80);
  @media(max-width:1024px){
    padding: px(10) px(15);
    min-height: px(70);
  }

  &__container{
    width: 100%;
    max-width: px(1680);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: px(59);
    width: px(59);
    &.mobile-logo{
      display: none;
      @media(max-width:1024px){
        display: inline-flex;
      }
    }
    @media(max-width:1200px){
      min-width: px(50);
      width: px(50);
    }
    @media(max-width:1024px){
      min-width: px(50);
      width: px(50);
      display: none;
    }
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }

  // header burger
  &__burger{
    display: none;
    width: px(30);
    height: px(30);
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-left: px(30);
    transition: .3s;
    background: transparent;
    border: none;
    outline: none;
    .icon{
      fill: $baseBlack;
      stroke: $baseBlack;
      stroke-width: 0.5px;
      transition: .3s;
      max-width: px(30);
      max-height: px(30);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .icon-close-burger{
      opacity: 0;
      visibility: hidden;
      max-height: px(20);
      stroke-width: 0.7px;
    }
    &.active{
      .icon-close-burger{
        opacity: 1;
        visibility: visible;
      }
      .icon-burger{
        opacity: 0;
        visibility: hidden;
      }
    }
    @media(max-width:1024px){
      display: flex;
    }
  }

  // header menu
  &__menu{
    display: inline-flex;
    align-items: center;
    li{
      margin: 0 px(14);
      @media(max-width:1650px){
        margin: 0 px(10);
      }
      @media(max-width:1500px){
        margin: 0 px(7);
      }
      @media(max-width:1180px){
        margin: 0 px(5);
      }
      @media(max-width:1024px){
        margin: 0;
        margin-bottom: px(20);
      }
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
        @media(max-width:1024px){
          margin-bottom: 0;
        }
      }
    }
    @media(max-width: 1024px){
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: px(40);
    }
  }

  &__link{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    color: $baseBlack;
    transition: .3s;
    z-index: 1;
    text-transform: uppercase;
    @media(max-width:1700px){
      font-size: px(15);
      line-height: px(18);
    }
    @media(max-width:1600px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1500px){
      font-size: px(13);
      line-height: px(17);
    }
    @media(max-width:1400px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1250px){
      font-size: px(13);
      line-height: px(17);
    }
    @media(max-width:1180px){
      font-size: px(12);
      line-height: px(16);
    }
    @media(max-width:1070px){
      text-transform: none;
      font-size: px(13);

    }
    @media(max-width:1024px){
      font-size: px(18);
      line-height: px(24);
      font-weight: 500;
    }
    &:after{
      width: 0%;
      height: 1px;
      background-color: $basePurple;
      transition: .3s;
      position: absolute;
      left: 0;
      bottom: 1px;
      content: '';
    }
    &.active{
      @media(min-width: 1025px){
        color: $basePurple;
        &::after{
          width: 100%;
        }
      }

    }
    &:hover{
      @media(min-width: 1025px){
        color: $basePurple;
        &::after{
          width: 100%;
        }
      }
    }
  }


  &-contacts-box{
    width: fit-content;
    display: flex;
    flex-direction: column;
    @media(max-width:1024px){
      margin-top: auto;
    }
    li{
      line-height: 1;
      display: flex;
      align-items: center;
      margin-bottom: px(12);
      &:last-child{
        margin-bottom: 0;
      }
      @media(max-width:1700px){
        margin-bottom: px(10);
      }
      @media(max-width:1024px){
        margin-bottom: px(15);
      }
    }
  }

  &-contact-link{
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    color: $baseBlack;
    transition: .3s;
    display: inline-flex;
    width: fit-content;
    @media(max-width:1700px){
      font-size: px(15);
      line-height: px(18);
    }
    @media(max-width:1600px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1400px){
      span{
        display: none;
      }
    }
    @media(max-width:1024px){
      font-size: px(16);
      line-height: px(20);
      font-weight: 500;
      span{
        display: block;
      }
    }
    .icon{
      max-width: px(20);
      max-height: px(20);
      min-width: px(20);
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      transition: .3s;
      fill: $basePurple;
      margin-right: px(15);
      &.stroke{
        fill: transparent;
        stroke: $basePurple;
        stroke-width: px(2);
      }
      @media(max-width:1600px){
        margin-right: px(10);
      }
      @media(max-width:1400px){
        margin-right: 0;
      }
      @media(max-width:1024px){
        margin-right: px(10);
      }
    }
    &:hover{
      @media(min-width: 1025px){
        color: $baseBlue;
        .icon{
          fill: $baseBlue;
          &.stroke{
            fill: transparent;
            stroke: $baseBlue;
          }
        }
      }
    }
  }

  &-mobile__menu-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: px(20);
    width: 100%;
    @media(max-width:1024px){
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  // mobile menu
  &-mobile__menu{
    width:100%;
    @media(max-width:1024px){
      position: fixed;
      top: px(70);
      z-index: 10;
      left: 0;
      background-color: $baseWhite;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 70px);
      padding: px(25) px(15);
      border-top: 1px solid $lightBlack;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transform: translateX(110%);
      transition: .4s;
      transition-delay: .15s;
      &.active{
        transform: translateX(0);
        transition-delay: 0;
      }
    }
  }
}
.overlay{
  position: fixed;
  z-index: 3;
  background-color: rgba($baseWhite , 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(2px);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backdrop-filter: blur(2px);
  &.active{
    opacity: 1;
    visibility: visible;
  }
}