.contacts-section{
  padding-top: px(96);
  padding-bottom: px(190);
  overflow: hidden;
  @media(max-width:1700px){
    padding-bottom: px(170);
    padding-top: px(80);
  }
  @media(max-width:1500px){
    padding-bottom: px(140);
    padding-top: px(70);
  }
  @media(max-width:1300px){
    padding-bottom: px(120);
  }
  @media(max-width:1024px){
    padding-bottom: px(60);
    padding-top: px(40);
  }

  &__title{
    margin-bottom: px(155);
    @media(max-width:1700px){
      margin-bottom: px(140);
    }
    @media(max-width:1500px){
      margin-bottom: px(110);
    }
    @media(max-width:1300px){
      margin-bottom: px(100);
    }
    @media(max-width:1024px){
      margin-bottom: px(55);
    }
  }

  &__wrapp {
    width: 100%;
    position: relative;
    max-width: px(1480);
    margin: 0 auto;
  }

  &__form{
    width: 100%;
    position: relative;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    box-shadow: 0px 19px 40px rgba(100, 100, 100, 0.23);
    border-radius: 20px;
    padding: px(80);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width:1700px){
      padding: px(70);
    }
    @media(max-width:1500px){
      padding: px(55);
    }
    @media(max-width:1300px){
      padding: px(50) px(40);
    }
    @media(max-width:1024px){
      padding: px(30) px(20);
      border-radius: px(10);
    }
    &::after{
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      content: '';
      position: absolute;
      background: $baseWhite;
      border-radius: px(19);
      top: 1px;
      left: 1px;
      z-index: -1;
      pointer-events: none;
      @media(max-width:1024px){
        border-radius: px(9);
      }
    }
  }

  &__form-thanks{
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: $baseWhite;
    border-radius: px(19);
    padding: px(20);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    .contacts-section__subtitle {
      line-height: px(32);
      margin-bottom: 0;
      @media(max-width:1700px){
        line-height: px(29);
      }
      @media(max-width:1500px){
        line-height: px(27);
      }
      @media(max-width:1024px){
        line-height: px(25);
      }
    }
    &.active{
      opacity: 1;
      visibility: visible;
    }
  }

  &__subtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: px(24);
    line-height: px(29);
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(60);
    @media(max-width:1700px){
      font-size: px(22);
      line-height: px(26);
      margin-bottom: px(50);
    }
    @media(max-width:1500px){
      font-size: px(20);
      line-height: px(24);
      margin-bottom: px(40);
    }
    @media(max-width:1024px){
      font-size: px(18);
      line-height: px(22);
      margin-bottom: px(30);
    }
  }

  &__btn{
    min-width: px(335);
    @media(max-width:450px){
      min-width: 100%;
    }
  }

  &__pulse-circle-1{
    width: px(430);
    top: -40%;
    left: -13%;
    @media(max-width:1700px){
      width: px(400);
      top: -45%;
    }
    @media(max-width:1500px){
      width: px(350);
      left: -11%;
    }
    @media(max-width:1200px){
      width: px(300);
      top: -40%;
    }
    @media(max-width:1024px){
      top: -27%;
      left: -12%;
    }
    @media(max-width:576px){
      width: px(200);
      top: -20%;
      left: -25%;
    }
  }

  &__pulse-circle-2{
    width: px(430);
    bottom: -40%;
    right: -13%;
    @media(max-width:1700px){
      width: px(400);
      bottom: -45%;
    }
    @media(max-width:1500px){
      width: px(350);
      right: -11%;
    }
    @media(max-width:1200px){
      width: px(300);
      bottom: -40%;
    }
    @media(max-width:1024px){
      bottom: -27%;
      right: -12%;
    }
    @media(max-width:576px){
      width: px(200);
      bottom: -20%;
      right: -25%;
    }
  }
}