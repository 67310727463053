.cases{
  &-section{
    padding-bottom: px(160);
    @media(max-width:1700px){
      padding-bottom: px(130);
    }
    @media(max-width:1500px){
      padding-bottom: px(100);
    }
    @media(max-width:1300px){
      padding-bottom: px(80);
    }
    @media(max-width:1024px){
      padding-bottom: px(50);
    }
  }

  &-section__title{
    margin-bottom: px(190);
    @media(max-width:1700px){
      margin-bottom: px(150);
    }
    @media(max-width:1500px){
      margin-bottom: px(120);
    }
    @media(max-width:1300px){
      margin-bottom: px(90);
    }
    @media(max-width:1024px){
      margin-bottom: px(40);
    }
  }

  &-box{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-list{
    width: 100%;
    position: relative;
  }

  &-item{
    padding: px(60) 0;
    margin-bottom: px(100);
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: px(24);
    @media(max-width:1700px){
      padding: px(40) 0;
      margin-bottom: px(80);
    }
    @media(max-width:1500px){
      padding: px(30) 0;
      margin-bottom: px(70);
      gap: px(18);
    }
    @media(max-width:1024px){
      padding: px(15) 0;
      gap: px(18);
      flex-direction: column;
      margin-bottom: px(60);
      gap: px(20);
    }
    @media(max-width:576px){
      margin-bottom: px(50);
    }
  }

  &-item__column {
    width: calc(33.33333333% - 16px);
    display: flex;
    flex-direction: column;
    position: relative;
    @media(max-width:1500px){
      width: calc(33.33333333% - 12px);
    }
    @media(max-width:1024px){
      width: 100% !important;
    }
    &:nth-child(1){
      width: calc(38% - 16px);
      @media(max-width:1500px){
        width: calc(38% - 12px);
      }
      &::after{
        width: px(304);
        aspect-ratio: 1 / 1;
        position: absolute;
        background: rgba(220, 167, 12, 0.24);
        border-radius: px(10);
        content: '';
        z-index: -1;
        left: 25%;
        bottom: 15%;
        @media(max-width:1700px){
          width: px(290);
        }
        @media(max-width:1500px){
          width: px(255);
        }
        @media(max-width:1300px){
          width: px(220);
        }
        @media(max-width:1024px){
          display: none !important;
        }
      }
    }
    &:nth-child(2){
      width: calc(51.7% - 16px);
      @media(max-width:1500px){
        width: calc(51.7% - 12px);
      }
      &::after{
        width: px(304);
        aspect-ratio: 1 / 1;
        position: absolute;
        background: rgba(220, 167, 12, 0.24);
        border-radius: px(10);
        content: '';
        z-index: -1;
        left: -18.7%;
        top: 15.5%;
        @media(max-width:1700px){
          width: px(290);
        }
        @media(max-width:1500px){
          width: px(255);
        }
        @media(max-width:1300px){
          width: px(220);
        }
        @media(max-width:1024px){
          display: none !important;
        }
      }
      &::before{
        width: px(304);
        aspect-ratio: 1 / 1;
        position: absolute;
        background: rgba(220, 167, 12, 0.24);
        border-radius: px(10);
        content: '';
        z-index: -1;
        right: -9%;
        bottom: 26%;
        @media(max-width:1700px){
          width: px(290);
        }
        @media(max-width:1500px){
          width: px(255);
        }
        @media(max-width:1300px){
          width: px(220);
        }
        @media(max-width:1024px){
          display: none !important;
        }
      }
    }
    &:nth-child(3){
      width: calc(10.3% - 16px);
      @media(max-width:1500px){
        width: calc(10.3% - 12px);
      }
    }
  }

  &-item__name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(36);
    line-height: px(42);
    text-transform: uppercase;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(4);
    @media(max-width:1700px){
      font-size: px(32);
      line-height: px(38);
    }
    @media(max-width:1500px){
      font-size: px(28);
      line-height: px(34);
    }
    @media(max-width:1300px){
      font-size: px(24);
      line-height: px(30);
    }
    @media(max-width:1024px){
      font-size: px(22);
      line-height: px(28);
    }
    @media(max-width:576px){
      font-size: px(20);
      line-height: px(24);
    }
  }

  &-item__description{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(30);
    color: #8A8A8A;
    margin-bottom: px(48);
    @media(max-width:1700px){
      font-size: px(16);
      line-height: px(20);
      margin-bottom: px(40);
    }
    @media(max-width:1300px){
      font-size: px(16);
      line-height: px(20);
      margin-bottom: px(30);
    }
    @media(max-width:576px){
      margin-bottom: px(20);
    }
  }

  &-item__content {
    width: 100%;
    position: relative;
    gap: px(24);
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media(max-width:1500px){
      gap: px(18);
    }
    @media(max-width:1024px){
      gap: px(20);
    }
  }
  &-item__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(24);
    line-height: px(29);
    text-transform: uppercase;
    color: $baseBlack;
    margin-bottom: px(20);
    @media(max-width:1700px){
      font-size: px(22);
      line-height: px(26);
      margin-bottom: px(18);
    }
    @media(max-width:1500px){
      font-size: px(20);
      line-height: px(24);
      margin-bottom: px(16);
    }
    @media(max-width:1024px){
      font-size: px(18);
      line-height: px(22);
      margin-bottom: px(16);
    }
    @media(max-width:576px){
      font-size: px(16);
      line-height: px(20);
      margin-bottom: px(14);
    }
  }

  &-item__textrow {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
    flex-wrap: wrap;
    grid-row-gap:px(24);
    @media(max-width:1500px){
      width: calc(100% + 18px);
      margin-left: -9px;
      margin-right: -9px;
      grid-row-gap:px(18);
    }
    &.double__row {
      .cases-item__text-wrap {
        width: 50%;
        @media(max-width:1200px){
          width: 100%;
        }
      }
    }
    &.tripple__row {
      .cases-item__text-wrap {
        width: 33.33333333333%;
        @media(max-width:1300px){
          width: 50%;
          &:nth-child(3){
            width: 100%;
          }
        }
        @media(max-width:1200px){
          width: 100%;
        }
      }
    }
  }

  &-item__text-wrap {
    width: 100%;
    padding: px(0) px(12);
    display: flex;
    flex-direction: column;
    @media(max-width:1500px){
      padding: 0 px(9);
    }
  }

  &-item__text{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: px(20);
    border: 1px solid #8A8A8A;
    border-radius: px(10);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(23);
    color: $baseBlack;
    background-color: $baseWhite;
    @media(max-width:1700px){
      font-size: px(15);
      line-height: px(21);
    }
    @media(max-width:1500px){
      padding: px(18);
      font-size: px(14);
      line-height: px(20);
    }
    @media(max-width:1024px){
      padding: px(16) !important;
      font-size: px(16);
      line-height: px(20);
    }
    @media(max-width:576px){
      font-size: px(14);
      line-height: px(18);
    }
    &.less-padding{
      padding-top: px(16);
      padding-bottom: px(16);
      @media(max-width:1500px){
        padding-top: px(12);
        padding-bottom: px(12);
      }
    }
    &.more-padding{
      padding-top: px(22);
      padding-bottom: px(22);
      @media(max-width:1500px){
        padding-top: px(18);
        padding-bottom: px(18);
      }
    }
    p{
      margin-bottom: px(15);
      @media(max-width:1500px){
        margin-bottom: px(12);
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    li{
      display: flex;
      align-items: center;
      padding-left: px(10);
      margin-bottom: px(15);
      position: relative;
      @media(max-width:1500px){
        margin-bottom: px(12);
      }
      &::before{
        width: px(3);
        min-width: px(3);
        height: px(3);
        border-radius: 50%;
        background: $baseBlack;
        content: '';
        position: absolute;
        left: px(-2);
        top: px(11);
        @media(max-width:1700px){
          top: px(10);
        }
        @media(max-width:1500px){
          top: px(9);
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &-item__photo {
    width: 100%;
    position: relative;
    img{
      width: 100%;
      object-fit: contain;
      pointer-events: none;
    }
    @media(max-width:1024px){
      max-width: px(800);
      margin: 0 auto;
    }
  }

  &-item__td{
    &.cases-item-padding-right{
      padding-right: px(130);
      @media(max-width:1400px){
        padding-right: px(88);
      }
      @media(max-width:1200px){
        padding-right: 0;
      }
    }
  }

  &-item__photorow {
    width: 100%;
    position: relative;
    display: flex;
    gap:px(24);
    @media(max-width:1500px){
      gap: px(18);
    }
    @media(max-width:1200px){
      flex-direction: column;
    }
    .cases-item__photo  {
      width: calc(65.9% - 12px + 123px);
      margin-left: -123px;
      @media(max-width:1500px){
        width: calc(65.9% - 12px + 129px);
        margin-left: -129px;
      }
      @media(max-width:1400px){
        width: calc(65.9% - 12px + 87px);
        margin-left: -87px;
      }
      @media(max-width:1200px){
        width: 100%;
        margin-left: 0;
      }
      @media(max-width:1024px){
        margin: 0 auto;
      }
      img{
        @media(max-width:1400px){
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .cases-item__text-wrap{
      width: calc(34.1% - 12px);
      padding: 0;
      @media(max-width:1200px){
        width: 100%;
      }
    }
    .cases-item__text{
      justify-content: center;
    }
  }

  &-item__smartphones{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:px(8);
    height: 100%;
    justify-content: space-between;
    @media(max-width:1024px){
      flex-direction: row;
      gap:px(20);
      .cases-item__photo {
        width: calc(100% / 3 - 13.3333333333333333px);
      }
    }
  }

  &-item__logorow{
    width: 100%;
    position: relative;
    display: flex;
    gap:px(14);
    justify-content: space-between;
    align-items: flex-end;

    .cases-item__sidebar{
      width: calc(72% - 7px);
      @media(max-width:1024px){
        width: 100%;
      }
    }
    .cases-item__photo {
      position: absolute;
      right: 0;
      width: calc(28% - 7px);
      bottom: -40%;
      @media(max-width:1500px){
        bottom: -35%;
      }
      @media(max-width:1300px){
        bottom: -29%;
      }
      @media(max-width:1200px){
        bottom: -25%;
      }
      @media(max-width:1024px){
        display: none;
      }
    }
  }

  &-item__row-photos{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: px(24);
    @media(max-width:1500px){
      gap: px(18);
    }
    @media(max-width:1024px){
      gap: px(20);
      flex-direction: column;
    }

    .cases-item__photo {
      width: calc(50% - 12px);
      @media(max-width:1500px){
        width: calc(50% - 9px);
      }
      @media(max-width:1024px){
        width: 100% !important;
        &:nth-child(1){
          display: none;
        }
      }
    }
  }

  // donella
  &-item{
    &.cases-item__donella{
      .cases-item__column{
        width: calc(33.33333333% - 16px);
        @media(max-width:1500px){
          width: calc(33.33333333% - 12px);
        }
        &:nth-child(1){
          &::after{
            background: rgba(227, 200, 180, 0.22);
            width: px(120);
            aspect-ratio: 1 / 2.66942149;
            left: 0;
            top: 18%;
            @media(max-width:1700px){
              width: px(110);
              top: 16%;
            }
            @media(max-width:1500px){
              width: px(90);
              top: 17%;
            }
          }
          &::before{
            width: px(320);
            aspect-ratio: 1 / 1;
            position: absolute;
            background: rgba(227, 200, 180, 0.22);
            border-radius: px(10);
            content: '';
            z-index: -1;
            right: -25.7%;
            bottom: 6%;
            @media(max-width:1700px){
              width: px(290);
            }
            @media(max-width:1500px){
              width: px(255);
            }
            @media(max-width:1300px){
              width: px(220);
            }
            @media(max-width:1024px){
              display: none !important;
            }
          }
        }
        &:nth-child(2){
          &::after, &::before{
            display: none;
          }
          .cases-item__td:nth-child(1) .cases-item__photo {
            @media(max-width:1300px){
              margin-top: 35%;
            }
            @media(max-width:1150px){
              display: none;
            }
            @media(max-width:1024px){
              display: block;
              margin-top: 0;
            }
            @media(max-width:576px){
              width: calc(100% + 80px);
              margin-left: -40px;
            }
          }
        }
        &:nth-child(3){
          .cases-item__content{
            @media(min-width: 1025px){
              gap: 0;
            }
          }
          .cases-item__photo{
            img{
              @media(min-width: 1025px){
                max-height: px(260);
              }
            }
            
          }
          .cases-item__td{
            &:nth-child(2){
              @media(max-width:1024px){
                display: none;
              }
            }
            &:nth-child(1){
              @media(max-width:1150px){
                width: calc(100% * 2 + 16px);
                margin-left: -105%;
              }
              @media(max-width:1024px){
                width: 100%;
                margin: 0;
              }
            }
          }

          &::before{
            width: px(320);
            aspect-ratio: 1 / 1;
            position: absolute;
            background: rgba(227, 200, 180, 0.22);
            border-radius: px(10);
            content: '';
            z-index: -1;
            right: 0;
            top: 0;
            @media(max-width:1700px){
              width: px(290);
            }
            @media(max-width:1500px){
              width: px(255);
            }
            @media(max-width:1300px){
              width: px(220);
            }
            @media(max-width:1024px){
              display: none !important;
            }
          }
          &::after{
            width: px(320);
            aspect-ratio: 1 / 1;
            position: absolute;
            background: rgba(227, 200, 180, 0.22);
            border-radius: px(10);
            content: '';
            z-index: -1;
            left: -29%;
            bottom: 32%;
            @media(max-width:1700px){
              width: px(290);
            }
            @media(max-width:1500px){
              width: px(255);
            }
            @media(max-width:1300px){
              width: px(220);
            }
            @media(max-width:1024px){
              display: none !important;
            }
          }
        }
      }

      .cases-item__description{
        @media(min-width: 1501px){
          width: calc(100% + 70px);
        }
      }

      .cases-item__textrow.double__row{
        .cases-item__text{
          @media(min-width: 1201px){
            min-height: px(136);
            justify-content: center;
          }
        }
      }
    }
  }
  // donella end

  // diamond
  &-item{
    &.cases-item__diamond{
      .cases-item__column{
        width: calc(50% - 16px);
        @media(max-width:1500px){
          width: calc(50% - 12px);
        }

        &:nth-child(1){
          .cases-item__row-photos {
            padding-left: px(20);
            @media(max-width:1024px){
              padding-left: 0;
            }
            .cases-item__photo {
              &:nth-child(1){
                width: calc(37.5% - 12px);
              }
              &:nth-child(2){
                width: calc(62.5% - 12px);
              }
            }
          }

          &::after{
            width: px(514);
            background: rgba(157, 206, 206, 0.28);
            left: 0;
            bottom: 12%;
            @media(max-width:1700px){
              width: px(450);
            }
            @media(max-width:1500px){
              width: px(400);
            }
            @media(max-width:1300px){
              width: px(340);
            }
          }
        }

        &:nth-child(2){
          .cases-item__row-photos {
            padding-right: px(20);
            .cases-item__photo {
              &:nth-child(1){
                width: calc(43.7% - 12px);
              }
              &:nth-child(2){
                width: calc(56.3% - 12px);
              }
            }
          }

          &::after{
            width: px(514);
            background: rgba(157, 206, 206, 0.28);
            left: unset;
            top: unset;
            right: 9%;
            bottom: 7%;
            @media(max-width:1700px){
              width: px(450);
            }
            @media(max-width:1500px){
              width: px(400);
            }
            @media(max-width:1300px){
              width: px(340);
            }
          }

          &::before{
            display: none;
          }
        }
      }
      .cases-item__text{
        padding-top: px(16);
        padding-bottom: px(16);
      }
      .cases-item__textrow.tripple__row{
        .cases-item__text{
          @media(min-width: 1201px){
            min-height: px(136);
            justify-content: center;
          }
        }
      }
    }
  }
  // diamond end

  // keep
  &-item{
    &.cases-item__keep{
      .cases-item__column{
        
        width: 10%;
        &:nth-child(1){
          width: calc(34.3% - 16px);
          @media(max-width:1500px){
            width: calc(34.3% - 12px);
          }
          &::after{
            width: px(390);
            background: rgba(2, 108, 94, 0.32);
            left: 0;
            bottom: 0;
            @media(max-width:1700px){
              width: px(330);
            }
            @media(max-width:1500px){
              width: px(280);
            }
            @media(max-width:1300px){
              width: px(220);
            }
          }
          .cases-item__photo {
            width: 87.5%;
            margin-left: auto;
          }

          .cases-item__content {
            @media(min-width: 1025px){
              justify-content: flex-start;
            }
          }
          .cases-item__td:nth-child(2){
            .cases-item__text-wrap{
              @media (min-width:1201px) and (max-width:1500px){
                width: 50%;
                &:nth-child(3){
                  width: 100%;
                }
              }
            }
          }
        }

        &:nth-child(2){
          z-index: 1;
          width: calc(33% - 16px);
          @media(max-width:1500px){
            width: calc(33% - 12px);
          }
          &::after{
            display: none;
          }

          .cases-item__photo {
            width: calc(100% + 55%);
            @media(max-width:1500px){
              width: calc(100% + 39%);
            }
            @media(max-width:1024px){
              width: 100%;
            }
          }

          &::before{
            display: none;
          }
          
        }

        &:nth-child(3){
          width: calc(32.7% - 16px);
          @media(max-width:1500px){
            width: calc(32.7% - 12px);
          }

          &::after{
            width: px(390);
            background: #F0F0F0;
            right: 4%;
            top: 4%;
            content: '';
            position: absolute;
            aspect-ratio: 1 / 1;
            border-radius: px(10);
            z-index: -1;
            @media(max-width:1700px){
              width: px(330);
            }
            @media(max-width:1500px){
              width: px(280);
            }
            @media(max-width:1300px){
              width: px(220);
            }
            @media(max-width:1024px){
              display: none;
            }
          }

          .cases-item__td{
            &:nth-child(1){
              padding-left: 55.5%;
              @media(max-width:1500px){
                padding-left: 40%;
              }
              @media(max-width:1024px){
                padding-left: 0;
              }
            }
          }
          .cases-item__content {
            @media(min-width: 1025px){
              justify-content: flex-end;
            }
          }
        }
      }
      .cases-item__textrow.tripple__row{
        .cases-item__text{
          @media(min-width: 1201px){
            min-height: px(112);
            padding-top: px(8);
            padding-bottom: px(8);
            justify-content: center;
          }
        }
      }
    }
  }
  // keep end
}