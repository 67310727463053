.reach{
  &-section{
    padding-bottom: 0;
  }

  &-section__title{
    @media(max-width:1024px){
      margin-bottom: px(50);
    }
  }

  &-statsbox{
    width: 100%;
    max-width: px(896);
    margin: 0 auto;
    margin-bottom: px(120);
    display: flex;
    gap: 0 px(50);
    justify-content: space-between;
    @media(max-width:1700px){
      margin-bottom: px(110);
    }
    @media(max-width:1500px){
      margin-bottom: px(90);
    }
    @media(max-width:1024px){
      max-width: px(600);
      gap: 0 px(20);
      margin-bottom: px(70);
    }
    @media(max-width:576px){
      flex-direction: column;
      margin-bottom: px(20);
    }
  }

  &-statscolumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(25.4% - 25px);
    @media(max-width:1024px){
      width: calc(30% - 10px);
    }
    @media(max-width:576px){
      width: 100%;
      justify-content: flex-start;
    }
    &.reach-graphic {
      justify-content: center;
      width: calc(49.2% - 50px);
      padding: px(20) 0;
      @media(max-width:1024px){
        width: calc(40% - 20px);
      }
      @media(max-width:576px){
        display: none;
      }
    }
    img{
      width: 100%;
      object-fit: contain;
    }
    &:last-child{
      .reach-stats__line{
        right: unset;
        left: -50px;
        background: linear-gradient(90deg, #6D7FF6 18.78%, #B75BFF 97.2%);
        @media(max-width:1024px){
          left: -20px;
        }
        &::after{
          left: unset;
          right: 0;
        }
      }
    }
  }

  &-stats__item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: calc(100% / 2 - 15px);
    padding-bottom: px(67);
    z-index: 1;
    @media(max-width:1024px){
      height: calc(100% / 2 - 10px);
      padding-bottom: px(50);
    }
    @media(max-width:576px){
      max-width: px(200);
      margin: 0 auto;
      height: auto;
      padding: 0 !important;
      margin-bottom: px(40);
    }
    &::before{
      width: px(10);
      height: px(10);
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      content: '';
      bottom: px(22);
      left: 50%;
      transform: translateX(-50%);
      background: #6F7EF6;
      @media(max-width:1024px){
        bottom: px(12);
      }
      @media(max-width:576px){
        display: none;
      }
    }
    &:last-child{
      padding-top: px(67);
      padding-bottom: 0;
      @media(max-width:1024px){
        padding-top: px(50);
      }
      &::before{
        bottom: unset;
        top: px(22);
        @media(max-width:1024px){
          top: px(12);
        }
      }
      .reach-stats__line{
        bottom: unset;
        top: 0;
        &::after{
          transform: translateY(0) rotate(0);
        }
      }
    }
  }

  &-stats__number{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(40);
    line-height: px(49);
    text-align: center;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(28);
    @media(max-width:1024px){
      font-size: px(30);
      line-height: px(34);
      margin-bottom: px(18);
    }
    @media(max-width:576px){
      margin-bottom: px(10);
      font-size: px(40);
      line-height: px(40);
    }
  }

  &-stats__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(24);
    text-align: center;
    color: $secondaryWhite;
    @media(max-width:1024px){
      font-size: px(15);
      line-height: px(20);
    }
    @media(max-width:576px){
      font-size: px(16);
      line-height: px(20);
    }
  }

  &-stats__line{
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    position: absolute;
    width: calc(50% + 50px);
    right: -50px;
    bottom: 0;
    height: 1px;
    @media(max-width:1024px){
      width: calc(50% + 20px);
      right: -20px;
    }
    @media(max-width:576px){
      display: none;
    }
    &::after{
      height: px(22);
      width: 1px;
      background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-100%) rotate(180deg);
      @media(max-width:1024px){
        height: px(12);
      }
    }
  }

  &-orderbox{
    width: 100%;
    max-width: px(572);
    margin: 0 auto;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    border-radius: 20px 20px 0px 0px;
    padding: px(40) px(44);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width:1700px){
      padding: px(40);
    }
    @media(max-width:1500px){
      padding: px(35) px(30);
    }
    @media(max-width:1024px){
      padding: px(30) px(20);
      border-radius: 10px 10px 0px 0px;
      max-width: px(600);
    }
  }

  &-orderbox__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: px(24);
    line-height: px(29);
    text-align: center;
    color: $baseWhite;
    margin-bottom: px(32);
    @media(max-width:1700px){
      font-size: px(22);
      line-height: px(26);
      margin-bottom: px(28);
    }
    @media(max-width:1500px){
      font-size: px(20);
      line-height: px(24);
    }
    @media(max-width:1024px){
      font-size: px(18);
      line-height: px(22);
      margin-bottom: px(20);
    }
  }
}