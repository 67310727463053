.technologies{
  &-section{
    padding-bottom: px(244);
    @media(max-width:1700px){
      padding-bottom: px(200);
    }
    @media(max-width:1500px){
      padding-bottom: px(180);
    }
    @media(max-width:1300px){
      padding-bottom: px(160);
    }
    @media(max-width:1024px){
      padding-bottom: px(120);
    }
    @media(max-width:576px){
      padding-bottom: px(20);
    }
  }

  &-section__title{
    margin-bottom: px(170);
    @media(max-width:1700px){
      margin-bottom: px(145);
    }
    @media(max-width:1500px){
      margin-bottom: px(135);
    }
    @media(max-width:1300px){
      margin-bottom: px(105);
    }
    @media(max-width:1024px){
      margin-bottom: px(75);
    }
    @media(max-width:576px){
      margin-bottom: px(50);
    }
  }

  &-box{
    width: 100%;
    position: relative;
    aspect-ratio: 1 / 1;
    max-width: px(480);
    z-index: 1;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:1024px){
      max-width: px(380);
    }

    @media(max-width:576px){
      aspect-ratio: unset;
    }
  }

  &__pulse-circle-1{
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
    .pulse-circle__large, .pulse-circle__medium, .pulse-circle__small{
      border-color: #8DADFC;
      // animation: none;
    }
    .pulse-circle__large, .pulse-circle__small{
      border-style: solid;
    }
    .pulse-circle__medium{
      border-style: dashed;
    }
    @media(max-width:576px){
      display: none;
    }
  }

  &__logo{
    width: px(149);
    position: relative;
    
    img{
      width: 100%;
      transform: translateY(4px);
      object-fit: contain;
      pointer-events: none;
      @media(max-width:1024px){
        transform: translateY(3px);
      }
    }
    @media(max-width:1024px){
      width: px(117);
    }
    @media(max-width:576px){
      display: none;
    }
  }

  &-list {
    @media(max-width:576px){
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
    }
  }

  &-item{
    width: 100%;
    max-width: px(193);
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width:1024px){
      max-width: px(160);
    }
    @media(max-width:576px){
      position: relative;
      top: unset !important;
      bottom: unset !important;
      left: unset !important;
      right: unset !important;
      transform: none !important;
      width: calc(100% / 2 - 10px);
      margin-bottom: px(30);
      max-width: none;
    }
    @media(max-width:450px){
      width: 100%;
      margin-bottom: px(40);
    }
    &:nth-child(1){
      top: px(-15);
      left: px(-20);
    }
    &:nth-child(2){
      top: px(-42);
      right: px(-15);
      @media(max-width:1024px){
        top: px(-17);
        right: px(-15);
      }
    }
    &:nth-child(3){
      top: px(230);
      left: px(-90);
      @media(max-width:1024px){
        top: px(200);
        left: px(-70);
      }
    }
    &:nth-child(4){
      top: px(239);
      right: px(-95);
      @media(max-width:1024px){
        top: px(200);
        right: px(-75);
      }
    }
    &:nth-child(5){
      bottom: px(-77);
      left: 50%;
      transform: translateX(-50%);
      @media(max-width:1024px){
        bottom: px(-67);
      }
    }
  }

  &-item__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: px(97);
    margin-bottom: px(15);
    img{
      max-width: 100%;
      object-fit: contain;
      @media(max-width:576px){
        aspect-ratio: 1 / 1;
      }
    }
    @media(max-width:1024px){
      max-width: px(60);
      margin-bottom: px(11);
    }
    @media(max-width:576px){
      max-width: px(70);
    }
    @media(max-width:450px){
      max-width: px(100);
      margin-bottom: px(13);
    }
  }

  &-item__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(24);
    text-align: center;
    color: $baseBlack;
    b{
      font-weight: 600;
    }
    @media(max-width:1024px){
      font-size: px(15);
      line-height: px(20);
    }
    @media(max-width:450px){
      font-size: px(16);
      max-width: px(250);
    }
  }
}