.website-section{
  padding-bottom: px(110);
  @media(max-width:1700px){
    padding-bottom: px(80);
  }
  @media(max-width:1500px){
    padding-bottom: px(60);
  }
  @media(max-width:1024px){
    padding-bottom: px(40);
  }
  @media(max-width:576px){
    padding-bottom: px(60);
  }

  &__title{
    margin-bottom: px(50);
    @media(max-width:576px){
      margin-bottom: px(40);
    }
  }


  &__roadmap{
    width: 100%;
    max-width: px(1400);
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding-top: px(106);
    @media(max-width:1700px){
      padding-top: px(120);
    }
    @media(max-width:1500px){
      max-width: px(1160);
      padding-top: px(82);
    }
    @media(max-width:1250px){
      max-width: px(920);
    }
    @media(max-width:1024px){
      max-width: none;
      padding-top: 0;
    }
  }

  &__roadmap-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: px(80);
    line-height: px(98);
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(257.73deg, #B75BFF 7.81%, #689AFF 87.61%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(4);
    @media(max-width:1700px){
      font-size: px(70);
      line-height: px(86);
    }
    @media(max-width:1500px){
      font-size: px(60);
      line-height: px(73);
      margin-bottom: px(6);
    }
    @media(max-width:1250px){
      font-size: px(50);
      line-height: px(64);
    }
    @media(max-width:1024px){
      margin-bottom: px(4);
      font-size: px(44);
      line-height: px(52);
    }
  }

  &__roadmap-subtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(24);
    line-height: px(29);
    text-align: center;
    text-transform: uppercase;
    color: $baseWhite;
    @media(max-width:1700px){
      font-size: px(22);
      line-height: px(26);
    }
    @media(max-width:1500px){
      font-size: px(20);
      line-height: px(24);
    }
    @media(max-width:1250px){
      font-size: px(18);
      line-height: px(22);
    }
    @media(max-width:1024px){
      margin-bottom: px(30);
    }
  }

  &__roadmap-list{
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: px(-82);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    pointer-events: none;
    @media(max-width:1500px){
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      margin-top: px(-75);
    }
    @media(max-width:1250px){
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
      margin-top: px(-70);
    }
    @media(max-width:1024px){
      margin-top: 0;
    }
    @media(max-width:576px){
      width: 100%;
      margin: 0;
    }
  }

  &__roadmap-col {
    padding: 0 px(20);
    width: 25%;
    display: flex;
    flex-direction: column;
    padding-top: px(52);
    padding-bottom: px(40);
    pointer-events: all;
    @media(max-width:1500px){
      padding: 0 px(15);
      padding-top: px(30);
      padding-bottom: px(30);
    }
    @media(max-width:1250px){
      padding: 0 px(10);
      padding-top: px(20);
      padding-bottom: px(20);
    }
    @media(max-width:1024px){
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: px(20);
      width: 50%;
    }
    @media(max-width:576px){
      padding: 0;
      width: 100%;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &:nth-child(4n-2){
      align-items: flex-start;
      margin-top: px(228);
      @media(max-width:1500px){
        margin-top: px(190);
      }
      @media(max-width:1250px){
        margin-top: px(160);
      }
      @media(max-width:1024px){
        margin-top: 0;
      }
      .line{
        height: px(85);
        transform: translate(-50%,-125px) rotate(180deg);
        @media(max-width:1500px){
          height: px(50);
          transform: translate(-50%,-70px) rotate(180deg);
        }
        @media(max-width:1250px){
          height: px(40);
          transform: translate(-50%,-60px) rotate(180deg);
        }
        &::before{
          width: px(170);
          transform: rotate(180deg) translateY(-50%);
          @media(max-width:1500px){
            width: px(130);
          }
          @media(max-width:1250px){
            width: px(98);
          }
        }
        &::after{
          right: px(168);
          top: 0;
          bottom: unset;
          height: px(52);
          transform: translateY(86px);
          @media(max-width:1500px){
            height: px(60);
            right: px(129);
            transform: translateY(49px);
          }
          @media(max-width:1250px){
            height: px(30);
            right: px(97);
            transform: translateY(39px);
          }
        }
      }
    }
    &:nth-child(4n-1){
      align-items: flex-end;
      margin-top: px(228);
      @media(max-width:1500px){
        margin-top: px(190);
      }
      @media(max-width:1250px){
        margin-top: px(160);
      }
      @media(max-width:1024px){
        margin-top: 0;
      }
      .line{
        height: px(85);
        transform: translate(-50%,-125px) rotate(180deg);
        @media(max-width:1500px){
          height: px(50);
          transform: translate(-50%,-70px) rotate(180deg);
        }
        @media(max-width:1250px){
          height: px(40);
          transform: translate(-50%,-60px) rotate(180deg);
        }
        &::before{
          left: 0;
          right: unset;
          width: px(170);
          transform:translateY(50%);
          @media(max-width:1500px){
            width: px(130);
          }
          @media(max-width:1250px){
            width: px(98);
          }
        }
        &::after{
          right: px(-168);
          height: px(52);
          bottom: 0;
          transform: rotate(0deg) translateY(100%);
          @media(max-width:1500px){
            height: px(60);
            right: px(-128);
            transform: translateY(100%);
          }
          @media(max-width:1250px){
            height: px(30);
            right: px(-96);
            transform: translateY(100%);
          }
        }
      }
    }

    &:nth-child(4n){
      .line{
        &::before{
          right: 0;
          bottom: 0;
          height: 2px;
          position: absolute;
          width: px(512);
          transform: translateY(0%) translateX(100%);
          content: '';
          @media(max-width:1500px){
            width: px(430);
          }
          @media(max-width:1250px){
            width: px(330);
          }
        }
        &::after{
          background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
          left: 512px;
          right: unset;
          bottom: 0;
          @media(max-width:1500px){
            left: px(430);
          }
          @media(max-width:1250px){
            left: px(330);
          }
        }
      }
    }
  }

  &__roadmap-item{
    width: 100%;
    max-width: px(300);
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    position: relative;
    box-shadow: 0px 9px 20px rgba(20, 20, 20, 0.4);
    border-radius: px(20);
    padding: px(40);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: px(188);
    max-height: px(188);
    z-index: 1;
    pointer-events: all;
    @media(max-width:1500px){
      height: px(160);
      max-height: px(160);
      padding: px(20);
      border-radius: px(16);
    }
    @media(max-width:1250px){
      height: px(140);
      max-height: px(140);
    }
    @media(max-width:1024px){
      height: 100%;
      max-height: none;
      border-radius: px(10);
      max-width: none;
    }
    &::before{
      width: px(12);
      height: px(12);
      background: #A767FF;
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,-52px);
      @media(max-width:1500px){
        width: px(10);
        height: px(10);
        transform: translate(-50%,-30px);
      }
      @media(max-width:1250px){
        transform: translate(-50%,-20px);
      }
      @media(max-width:1024px){
        display: none;
      }
    }
    
    &::after{
      position: absolute;
      background: $blackBg;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1px;
      left: 1px;
      border-radius: px(19);
      content: '';
      z-index: -1;
      @media(max-width:1500px){
        border-radius: px(15);
      }
      @media(max-width:1024px){
        border-radius: px(9);
      }
    }

    .line{
      background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
      width: 2px;
      height: px(165);
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%,-206px) rotate(180deg);
      z-index: -1;
      @media(max-width:1500px){
        height: px(120);
        transform: translate(-50%,-140px) rotate(180deg);
      }
      @media(max-width:1250px){
        height: px(100);
        transform: translate(-50%,-120px) rotate(180deg);
      }
      @media(max-width:1024px){
        display: none;
      }
      &::before{
        background: linear-gradient(267.06deg, #B75BFF -15.88%, #689AFF 122.47%);
        right: 0;
        bottom: 0;
        height: 2px;
        position: absolute;
        width: px(530);
        transform: rotate(180deg) translateY(0);
        content: '';
        @media(max-width:1500px){
          width: px(425);
        }
        @media(max-width:1250px){
          width: px(330);
        }
      }
      &::after{
        background: linear-gradient(180deg, #C175FD 0%, #84ADFF 100%);
        right: 529.5px;
        bottom: 0;
        height: px(90);
        position: absolute;
        width: px(2);
        transform: rotate(180deg);
        content: '';
        @media(max-width:1500px){
          height: px(70);
          right: px(424);
        }
        @media(max-width:1250px){
          height: px(60);
          right: px(329);
        }
      }
    }
  }

  &__roadmap-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: px(24);
    line-height: px(36);
    text-align: center;
    color: #FAFAFA;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media(max-width:1700px){
      font-size: px(22);
      line-height: px(32);
    }
    @media(max-width:1500px){
      font-size: px(20);
      line-height: px(28);
    }
    @media(max-width:1250px){
      font-size: px(18);
      line-height: px(24);
    }
    @media(max-width:1250px){
      font-size: px(18);
      line-height: px(24);
    }
    @media(max-width:1024px){
      -webkit-line-clamp: 100;
      font-size: px(16);
      line-height: px(20);
    }
  }
}