.global-btn{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: px(20);
  line-height: px(24);
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  min-height: px(70);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: px(335);
  padding: px(15);
  overflow: hidden;
  background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
  border-radius: px(10);
  color: $baseWhite;
  transition: .3s;
  &::after{
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(90deg, #B75BFF 18.78%, #6D7FF6 97.2%);
    position: absolute;
    opacity: 0;
    transition: .3s;
  }
  &:hover{
    @media(min-width: 1025px){
      &::after{
        opacity: 1;
      }
    }
  }
  @media(max-width:1700px){
    font-size: px(18);
    line-height: px(22);
    min-height: px(60);
  }
  @media(max-width:1500px){
    font-size: px(16);
    line-height: px(20);
  }
  @media(max-width:1024px){
    font-size: px(14);
    line-height: px(18);
    min-height: px(50);
    min-width: px(280);
    border-radius: px(6);
  }
  @media(max-width:450px){
    min-width: 100%;
  }
}

.white-btn{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: px(20);
  line-height: px(24);
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  min-height: px(70);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: px(335);
  padding: px(15);
  overflow: hidden;
  background: $baseWhite;
  border-radius: px(10);
  color: $baseBlack;
  transition: .3s;
  &:hover{
    @media(min-width: 1025px){
      color: $baseWhite;
      background: $blackBg;
    }
  }
  @media(max-width:1700px){
    font-size: px(18);
    line-height: px(22);
    min-height: px(60);
  }
  @media(max-width:1500px){
    font-size: px(16);
    line-height: px(20);
  }
  @media(max-width:1024px){
    font-size: px(14);
    line-height: px(18);
    min-height: px(50);
    min-width: px(280);
    border-radius: px(6);
  }
  @media(max-width:450px){
    min-width: 100%;
  }
}


.global-play-button{
  width: px(140);
  aspect-ratio: 1 / 1.25714286;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: .3s;
  @media(max-width:1700px){
    width: px(125);
  }
  @media(max-width:1500px){
    width: px(110);
  }
  @media(max-width:1300px){
    width: px(90);
  }
  @media(max-width:1024px){
    width: px(70);
  }
  @media(max-width:576px){
    width: px(50);
  }
  img{
    width: 100%;
    pointer-events: none;
    object-fit: contain;
  }
  &:hover{
    @media(min-width: 1025px){
      top: 49.5%;
    }
  }
  &.hide{
    opacity: 0;
    pointer-events: none;
  }
}