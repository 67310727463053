* {
  padding: 0;
  margin: 0;
  outline: none;
}

.lock {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}

.important__none{
  display: none !important;
}

.disable-display {
  display: none !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  line-height: 1.3;
  font-size: px(16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: $baseWhite;
  color: $baseBlack;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: $baseBlack;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  font: inherit;
  outline: none !important;
  border: none;
  background: transparent;
  border-radius: 0;
  appearance: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.content {
  flex: 1 0 auto;
}

.global-section {
  width: 100%;
  position: relative;
  padding: px(96) px(30);
  z-index: 1;
  @media(max-width:1700px){
    padding: px(80) px(30);
  }
  @media(max-width:1500px){
    padding: px(70) px(30);
  }
  @media(max-width:1024px){
    padding: px(40) px(15);
  }
  &.bg-section-dark{
    background: $blackBg;
  }
}

.container {
  width: 100%;
  max-width: px(1680);
  margin: 0 auto;
}

.global-socials{
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: px(10) px(20);
  justify-content: center;
}

.global-social__item {
  width: px(28);
  height: px(28);
  display: flex;
  align-items: center;
  justify-content: center;
  .icon{
    width: inherit;
    height: inherit;
    object-fit: contain;
    fill: $basePurple;
    transition: .3s;
  }
  @media(max-width:1700px){
    width: px(24);
    height: px(24);
  }
  @media(max-width:1024px){
    width: px(22);
    height: px(22);
  }
  &:hover{
    @media(min-width:1025px){
      .icon{
        fill: $baseBlue;
      }
    }
  }
}

.swiper-container {
  overflow: hidden;
}

button {
  cursor: pointer;
}