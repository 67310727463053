.hero {
  &-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding-top: px(140);
    padding-bottom: px(120);
    overflow: hidden;
    @media(max-width:1500px){
      padding-top: px(130);
      padding-bottom: px(100);
    }
    @media(max-width:1024px){
      min-height: px(650);
      padding-top: px(110);
      padding-bottom: px(60);
    }
    @media(max-width:700px){
      padding-top: px(110);
      padding-bottom: px(40);
    }
    @media(max-width:576px){
      min-height:px(500);
      height: calc(100vh - 80px);
    }
    @media(min-width:1025px) and (min-height:1011px){
      min-height: px(1010);
    }

    &::before{
      width: px(600);
      aspect-ratio: 1 / 1;
      background: rgba(183, 91, 255, 0.26);
      filter: blur(300px);
      position: absolute;
      z-index: -1;
      content: '';
      top: 0;
      left: -22%;
      @media(max-width:1700px){
        width: px(500);
        left: -18%;
      }
      @media(max-width:1500px){
        width: px(400);
        left: -12%;
      }
      @media(max-width:1200px){
        width: px(350);
        left: -5%;
      }
      @media(max-width:1024px){
        display: none;
      }
    }

    &::after{
      width: px(600);
      aspect-ratio: 1 / 1;
      background: rgba(116, 171, 255, 0.3);
      filter: blur(300px);
      position: absolute;
      z-index: -1;
      content: '';
      bottom: -15%;
      right: -10%;
      @media(max-width:1700px){
        width: px(500);
      }
      @media(max-width:1500px){
        width: px(400);
        bottom: -5%;
      }
      @media(max-width:1200px){
        width: px(350);
        bottom: -5%;
        right: -5%;
      }
      @media(max-width:1024px){
        display: none;
      }
    }

    .container {
      margin: auto;
    }
  }
  &-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: px(750);
    margin: 0 auto;
  }

  &-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: px(128);
    line-height: 141.4%;
    text-transform: uppercase;
    background: linear-gradient(90deg, #B75BFF 18.78%, #689BFF 97.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(40);
    text-align: center;
    @media(max-width:1700px){
      font-size: px(110);
      margin-bottom: px(30);
      line-height: 130%;
    }
    @media(max-width:1500px){
      font-size: px(100);
      margin-bottom: px(20);
      line-height: 120%;
    }
    @media(max-width:1024px){
      font-size: px(64);
    }
    @media(max-width:576px){
      font-size: px(45);
    }
  }

  &-subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: px(24);
    line-height: px(26);
    text-align: center;
    margin-bottom: px(24);
    color: $baseBlack;
    max-width: px(620);
    @media(max-width:1700px){
      font-size: px(22);
      line-height: px(24);
      margin-bottom: px(20);
    }
    @media(max-width:1500px){
      font-size: px(20);
      line-height: px(22);
    }
    @media(max-width:1024px){
      font-size: px(18);
      line-height: px(20);
    }
    @media(max-width:576px){
      font-size: px(16);
      line-height: px(18);
    }
    @media(max-width:450px){
      max-width: px(270);
    }
  }

  &__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(27);
    text-align: center;
    margin-bottom: px(50);
    max-width: px(535);
    @media(max-width:1700px){
      font-size: px(16);
      line-height: px(24);
      margin-bottom: px(40);
    }
    @media(max-width:1024px){
      margin-bottom: px(30);
    }
    @media(max-width:576px){
      font-size: px(14);
      line-height: px(20);
    }
  }

  &-hide__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: px(48);
    line-height: px(59);
    text-transform: uppercase;
    color: rgba(104, 154, 255, 0.12);
    width: 100%;
    text-align: center;
    position: absolute;
    top: px(80);
    left: 0;
    pointer-events: none;
    @media(max-width:1700px){
      font-size: px(40);
      line-height: px(50);
    }
    @media(max-width:1500px){
      font-size: px(36);
      line-height: px(46);
    }
    @media(max-width:1200px){
      font-size: px(30);
      line-height: px(40);
    }
    @media(max-width:1024px){
      font-size: px(20);
      line-height: px(28);
      top: px(70);
    }
    @media(max-width:700px){
      display: none;
    }
  }

  &__btn{
    @media(max-width:340px){
      min-width: 100%;
    }
  }

  &__pulse-circle-1{
    width: px(1110);
    top: -50%;
    left: -20%;
    @media(max-width:1700px){
      width: px(900);
      top: -38%;
      left: -22%;
    }
    @media(max-width:1500px){
      width: px(700);
      top: -28%;
      left: -22%;
    }
    @media(max-width:1200px){
      width: px(550);
      top: -21%;
      left: -20%;
    }
    @media(max-width:1024px){
      width: px(400);
    }
    @media(max-width:576px){
      width: px(300);
      top: -15%;
    }
  }

  &__pulse-circle-2{
    width: px(1110);
    bottom: -50%;
    right: -23%;
    @media(max-width:1700px){
      width: px(900);
      bottom: -40%;
      right: -25%;
    }
    @media(max-width:1500px){
      width: px(700);
      bottom: -33%;
      right: -22%;
    }
    @media(max-width:1200px){
      width: px(550);
      bottom: -25%;
      right: -20%;
    }
    @media(max-width:1024px){
      width: px(400);
    }
    @media(max-width:576px){
      width: px(300);
    }
  }
}
